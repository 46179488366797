import type {
    ISizing,
    TElevationTokens,
    TOpacityTokens
} from '../../../defines/theme.types.js';

const opacity: TOpacityTokens = {
    '0': 0,
    '4': 0.04,
    '6': 0.06,
    '10': 0.1,
    '12': 0.12,
    '17': 0.17,
    '20': 0.2,
    '22': 0.22,
    '24': 0.24,
    '25': 0.25,
    '30': 0.3,
    '35': 0.35,
    '40': 0.4,
    '50': 0.5,
    '60': 0.6,
    '70': 0.7,
    '80': 0.8,
    '87': 0.87,
    '90': 0.9,
    '92': 0.92,
    '94': 0.94,
    '95': 0.95,
    '96': 0.96,
    '98': 0.98,
    '99': 0.99,
    '100': 1
};

const elevation: TElevationTokens = {
    e0: 'none',
    e1: '0px 2px 3px rgba(74, 74, 74, 0.2)',
    e2: '0px 4px 6px rgba(74, 74, 74, 0.18)',
    e3: '0px 6px 9px rgba(74, 74, 74, 0.16)',
    e4: '0px 8px 12px rgba(74, 74, 74, 0.14)',
    e5: '0px 10px 15px rgba(74, 74, 74, 0.12)',
    e6: '0px 12px 18px rgba(74, 74, 74, 0.1)',
    e7: '0px 14px 21px rgba(74, 74, 74, 0.1)',
    e8: '0px 16px 24px rgba(74, 74, 74, 0.1)',
    e9: '0px 18px 27px rgba(74, 74, 74, 0.1)',
    e10: '0px 20px 30px rgba(74, 74, 74, 0.1)'
};

const sizing: ISizing = {
    layout: {
        s0: '0.125rem',
        s1: '0.25rem',
        s2: '0.5rem',
        s3: '0.75rem',
        s4: '1rem',
        s5: '1.5rem',
        s6: '2rem',
        s7: '2.5rem',
        s8: '3rem',
        s9: '3.5rem',
        s10: '4rem',
        inherit: 'inherit'
    },
    font: {
        s0: '0.2em',
        s1: '0.4em',
        s2: '0.75em',
        s3: '1em',
        s4: '1.5em',
        s5: '2em',
        s6: '2.5em',
        s7: '3em',
        s8: '3.5em',
        s9: '4em',
        s10: '5em',
        inherit: 'inherit'
    },
    fixed: {
        s0: '2px',
        s1: '4px',
        s2: '8px',
        s3: '12px',
        s4: '16px',
        s5: '24px',
        s6: '32px',
        s7: '40px',
        s8: '48px',
        s9: '56px',
        s10: '80px',
        inherit: 'inherit'
    }
};

export {elevation, opacity, sizing};
