import {createThemePack} from '../../utils/create-theme-pack/create-theme-pack.js';
import {createTokens} from '../../utils/create-tokens/create-tokens.js';
import {baseColors, tonal} from './standard.tokens.js';

const tokens = createTokens(baseColors, {tonal});

const STANDARD_THEME_PACK = createThemePack({
    key: 'standard',
    tokens
});

export {STANDARD_THEME_PACK};
