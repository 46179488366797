import type {FC} from 'react';
import type {IIconProps} from '../../defines/common.types.js';

const OTPIcon: FC<IIconProps> = (props) => {
    return (
        <svg
            xmlns={'http://www.w3.org/2000/svg'}
            viewBox={'0 0 64 64'}
            width={'24px'}
            height={'24px'}
            {...props}
        >
            <g>
                <path
                    d={
                        'M43.207,47.293a.9994.9994,0,0,0-1.414,0L38.5,50.5859,35.207,47.293a1,1,0,0,0-1.414,1.414L37.0859,52,33.793,55.293a1,1,0,1,0,1.414,1.414L38.5,53.4141l3.293,3.2929a1,1,0,0,0,1.414-1.414L39.9141,52l3.2929-3.293A.9994.9994,0,0,0,43.207,47.293Z'
                    }
                />
                <path
                    d={
                        'M56.207,47.293a.9994.9994,0,0,0-1.414,0L51.5,50.5859,48.207,47.293a1,1,0,0,0-1.414,1.414L50.0859,52,46.793,55.293a1,1,0,1,0,1.414,1.414L51.5,53.4141l3.293,3.2929a1,1,0,0,0,1.414-1.414L52.9141,52l3.2929-3.293A.9994.9994,0,0,0,56.207,47.293Z'
                    }
                />
                <path
                    d={
                        'M12.5,50.5859,9.207,47.293a1,1,0,1,0-1.414,1.414L11.0859,52,7.793,55.293a1,1,0,1,0,1.414,1.414L12.5,53.4141l3.293,3.2929a1,1,0,0,0,1.414-1.414L13.9141,52l3.2929-3.293a1,1,0,0,0-1.414-1.414Z'
                    }
                />
                <path
                    d={
                        'M30.207,47.293a.9994.9994,0,0,0-1.414,0L25.5,50.5859,22.207,47.293a1,1,0,0,0-1.414,1.414L24.0859,52,20.793,55.293a1,1,0,1,0,1.414,1.414L25.5,53.4141l3.293,3.2929a1,1,0,0,0,1.414-1.414L26.9141,52l3.2929-3.293A.9994.9994,0,0,0,30.207,47.293Z'
                    }
                />
                <path
                    d={
                        'M56,42H49a1,1,0,0,0,0,2h7a5.0059,5.0059,0,0,1,5,5v6a5.0059,5.0059,0,0,1-5,5H8a5.0059,5.0059,0,0,1-5-5V49a5.0059,5.0059,0,0,1,5-5h7a1,1,0,0,0,0-2H8a7.0078,7.0078,0,0,0-7,7v6a7.0078,7.0078,0,0,0,7,7H56a7.0081,7.0081,0,0,0,7-7V49A7.0081,7.0081,0,0,0,56,42Z'
                    }
                />
                <path
                    d={
                        'M24,44H40a7.0081,7.0081,0,0,0,7-7V23a7.0081,7.0081,0,0,0-7-7V10a8,8,0,0,0-16,0v6a7.0078,7.0078,0,0,0-7,7V37A7.0078,7.0078,0,0,0,24,44Zm2-34a6,6,0,0,1,12,0v6H26ZM19,23a5.0059,5.0059,0,0,1,5-5H40a5.0059,5.0059,0,0,1,5,5V37a5.0059,5.0059,0,0,1-5,5H24a5.0059,5.0059,0,0,1-5-5Z'
                    }
                />
                <path
                    d={
                        'M29,30.9805V35a3,3,0,0,0,6,0V30.98a4.9992,4.9992,0,1,0-6,.001Zm.0942-4.7466a2.98,2.98,0,0,1,2.1724-2.1485,3.2765,3.2765,0,0,1,.7393-.0864,2.9574,2.9574,0,0,1,1.8584.65,2.9826,2.9826,0,0,1-.3672,4.9317A1,1,0,0,0,33,30.4453V35a1,1,0,0,1-2,0V30.4453a1,1,0,0,0-.4971-.8642A2.9765,2.9765,0,0,1,29.0942,26.2339Z'
                    }
                />
            </g>
        </svg>
    );
};

export {OTPIcon};
