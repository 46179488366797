import {default as styled} from '@emotion/styled';
import type {ButtonProps} from '@mui/base';
import {Button as BaseButton, buttonClasses} from '@mui/base';
import {alpha} from '@mui/system';
import type {FC} from 'react';

const StyledButton = styled(BaseButton)(({theme}) => {
    return `
    word-wrap: break-word;
    box-sizing: border-box;
    text-decoration: none;
    display: inline-block;
    padding: 8px 24px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    border: 1px solid;
    border-radius: 6px;
    appearance: none;
    transition: 80ms cubic-bezier(0.33, 1, 0.68, 1);
    transition-property: color, background-color, box-shadow, border-color;
    color: ${theme.palette.accent.primary.high.contrast};
    background-color: ${theme.palette.accent.primary.high.main};
    border-color: ${theme.palette.surface.contrast.low};
    box-shadow: ${theme.elevation.low};
    margin-left: 16px !important;
    text-align: center !important;

    &:hover {
      transition-duration: 80ms;
    }

    &:active {
      transition: none;
    }

    &:disabled {
      cursor: not-allowed;
      box-shadow: none;
    }

    &:hover:not(.${buttonClasses.disabled}) {
      background-color: ${theme.palette.accent.primary.high.state.hover};
    }

    &:active:not(.${buttonClasses.disabled}) {
      background-color: ${theme.palette.accent.primary.high.state.active};
      box-shadow: ${theme.elevation.mid}
    }

    &.${buttonClasses.disabled} {
      color: ${alpha(theme.palette.accent.primary.high.contrast, 0.5)};
      background-color: ${alpha(theme.palette.accent.primary.high.main, 0.6)};
    }
  `;
});

const Button: FC<ButtonProps> = (props) => {
    return <StyledButton {...props} />;
};

export {Button};
