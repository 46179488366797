import {default as styled} from '@emotion/styled';
import type {FC, ReactNode} from 'react';
import {RotateIcon} from '../../assets/icons/rotate-icon.js';
import {ButtonIcon} from '../button-icon/button-icon.js';
import {Text} from '../text/text.js';

const Container = styled('div')(({theme}) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.sizing.layout.s7
}));

const Content = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative'
});

const IconArea = styled('span')(({theme}) => ({
    marginTop: theme.sizing.font.s2
}));

interface ICardSideAProps {
    icon: ReactNode;
    title: string;
}

const CardSideA: FC<ICardSideAProps> = ({icon, title}) => {
    return (
        <Container>
            <Content>
                <Text variant={'span'}>{icon}</Text>

                <Text
                    variant={'h4'}
                    margin={{
                        top: 's2',
                        bottom: 's2'
                    }}
                    align={'center'}
                >
                    {title}
                </Text>

                <IconArea>
                    <ButtonIcon>
                        <RotateIcon height={50} width={50} />
                    </ButtonIcon>
                </IconArea>
            </Content>
        </Container>
    );
};

export type {ICardSideAProps};
export {CardSideA};
