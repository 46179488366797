import type {IAllTokens, IThemePack} from '../../defines/theme.types.js';
import {createTheme} from '../create-theme/create-theme.js';

interface IThemesOptions {
    key: string;
    tokens: IAllTokens;
}

function createThemePack({key, tokens}: IThemesOptions): IThemePack {
    return {
        key,
        tokens,
        themes: {
            light: createTheme({
                key: `${key}-light`,
                tokens,
                type: 'light'
            }),
            dark: createTheme({
                key: `${key}-dark`,
                tokens,
                type: 'dark'
            })
        }
    };
}

export {createThemePack};
