import type {FC} from 'react';
import type {IIconProps} from '../../defines/common.types.js';

const ClockIcon: FC<IIconProps> = (props) => {
    return (
        <svg
            xmlns={'http://www.w3.org/2000/svg'}
            xmlnsXlink={'http://www.w3.org/1999/xlink'}
            viewBox={'0 0 50 50'}
            width={'24px'}
            height={'24px'}
            {...props}
        >
            <circle
                style={{
                    fill: 'none',
                    stroke: 'currentcolor',
                    strokeWidth: 2,
                    strokeLinecap: 'round',
                    strokeMiterlimit: 10
                }}
                cx={'25'}
                cy={'25'}
                r={'22'}
            />
            <circle cx={'25'} cy={'25'} r={'3'} />
            <polyline
                style={{
                    fill: 'none',
                    stroke: 'currentcolor',
                    strokeWidth: 2,
                    strokeLinecap: 'round',
                    strokeMiterlimit: 10
                }}
                points={'30,34 25,25 37,12 '}
            />
        </svg>
    );
};

export {ClockIcon};
