import type {
    IAllTokens,
    ITheme,
    TThemeType
} from '../../defines/theme.types.js';
import {createPalette} from '../create-palette/create-palette.js';

interface IThemeOptions {
    key: string;
    tokens: IAllTokens;
    type: TThemeType;
    skipCache?: boolean;
}

const cache: Map<string, ITheme> = new Map();

function createTheme({key, tokens, type, skipCache}: IThemeOptions): ITheme {
    const cached = cache.get(key);

    if (cached && !skipCache) {
        console.warn(
            `createTheme - THEME WITH KEY "${key}" ALREADY EXISTS. RETURNING CACHED VERSION`
        );

        return cached;
    }

    const {tonal, opacity, elevation, sizing} = tokens;

    // https://m3.material.io/styles/color/the-color-system/tokens
    const palette = createPalette(tonal, type);

    const breakpoint = {
        xs: 575,
        s: 767,
        m: 991,
        l: 1199,
        xl: 1399,
        xxl: 1919
    };

    const theme: ITheme = {
        key,
        type,
        palette,

        state: {
            disabled: {
                opacity: {
                    element: opacity['50'],
                    overlay: opacity['20']
                },
                elevation: `0 0 0 2px ${palette.accent.neutral.high.main}`
            },
            hover: {
                opacity: {
                    element: opacity['95'],
                    overlay: opacity['20']
                },
                elevation: `0 0 0 2px ${palette.accent.primary.high.main}`
            },
            focus: {
                opacity: {
                    element: opacity['95'],
                    overlay: opacity['20']
                },
                elevation: `0 0 0 2px ${palette.accent.primary.high.main}`
            },
            active: {
                opacity: {
                    element: opacity['95'],
                    overlay: opacity['20']
                },
                elevation: `0 0 0 2px ${palette.accent.primary.high.main}`
            },
            pressed: {
                opacity: {
                    element: opacity['95'],
                    overlay: opacity['20']
                },
                elevation: `0 0 0 2px ${palette.accent.primary.high.main}`
            },
            dragged: {
                opacity: {
                    element: opacity['95'],
                    overlay: opacity['20']
                },
                elevation: `0 0 0 2px ${palette.accent.primary.high.main}`
            }
        },

        elevation: {
            none: elevation.e0,
            low: elevation.e1,
            mid: elevation.e3,
            high: elevation.e7
        },

        breakpoint,

        query: {
            xs: '@media (min-width: 0px)',
            s: `@media (min-width: ${breakpoint.xs}px)`,
            m: `@media (min-width: ${breakpoint.s}px)`,
            l: `@media (min-width: ${breakpoint.m}px)`,
            xl: `@media (min-width: ${breakpoint.l}px)`,
            xxl: `@media (min-width: ${breakpoint.xl}px)`
        },

        zIndex: {
            global: {
                lowest: 100,
                low: 200,
                mid: 500,
                high: 5000,
                highest: 10000,
                top: 50000
            },
            local: {
                lowest: 10,
                low: 20,
                mid: 30,
                high: 40,
                highest: 50,
                top: 60
            }
        },

        // THE FOLLOWING ARE USED AS DEFAULT VALUES THAT CAN BE OVERRIDDEN LOCALLY
        typography: {
            variant: {
                h1: {
                    component: 'h1',
                    typeface: 'Roboto',
                    styling: 'normal',
                    size: sizing.font.s7,
                    weight: 400,
                    align: 'left',
                    color: palette.accent.primary.high.main,
                    decoration: 'none',
                    shouldWrap: true,
                    lineHeight: 'normal',
                    stretch: 'normal'
                },
                h2: {
                    component: 'h2',
                    typeface: 'Roboto',
                    styling: 'normal',
                    size: sizing.font.s6,
                    weight: 400,
                    align: 'left',
                    color: palette.accent.primary.high.main,
                    decoration: 'none',
                    shouldWrap: true,
                    lineHeight: 'normal',
                    stretch: 'normal'
                },
                h3: {
                    component: 'h3',
                    typeface: 'Roboto',
                    styling: 'normal',
                    size: sizing.font.s5,
                    weight: 400,
                    align: 'left',
                    color: palette.accent.primary.high.main,
                    decoration: 'none',
                    shouldWrap: true,
                    lineHeight: 'normal',
                    stretch: 'normal'
                },
                h4: {
                    component: 'h4',
                    typeface: 'Roboto',
                    styling: 'normal',
                    size: sizing.font.s4,
                    weight: 400,
                    align: 'left',
                    color: palette.surface.contrast.high,
                    decoration: 'none',
                    shouldWrap: true,
                    lineHeight: 'normal',
                    stretch: 'normal'
                },
                h5: {
                    component: 'h5',
                    typeface: 'Roboto',
                    styling: 'normal',
                    size: sizing.font.s3,
                    weight: 400,
                    align: 'left',
                    color: palette.surface.contrast.low,
                    decoration: 'none',
                    shouldWrap: true,
                    lineHeight: 'normal',
                    stretch: 'normal'
                },
                h6: {
                    component: 'h6',
                    typeface: 'Roboto',
                    styling: 'normal',
                    size: sizing.font.s2,
                    weight: 400,
                    align: 'left',
                    color: palette.surface.contrast.low,
                    decoration: 'none',
                    shouldWrap: true,
                    lineHeight: 'normal',
                    stretch: 'normal'
                },
                p: {
                    component: 'p',
                    typeface: 'Roboto',
                    styling: 'normal',
                    size: sizing.font.s3,
                    weight: 400,
                    align: 'left',
                    color: palette.surface.contrast.high,
                    decoration: 'none',
                    shouldWrap: true,
                    lineHeight: 'normal',
                    stretch: 'normal'
                },
                span: {
                    component: 'span',
                    typeface: 'Roboto',
                    styling: 'normal',
                    size: sizing.font.s3,
                    weight: 400,
                    align: 'left',
                    color: palette.surface.contrast.high,
                    decoration: 'none',
                    shouldWrap: true,
                    lineHeight: 'normal',
                    stretch: 'normal'
                },
                caption: {
                    component: 'span',
                    typeface: 'Roboto',
                    styling: 'normal',
                    size: sizing.font.s4,
                    weight: 400,
                    align: 'left',
                    color: palette.surface.contrast.high,
                    decoration: 'none',
                    shouldWrap: true,
                    lineHeight: 'normal',
                    stretch: 'normal'
                },
                button: {
                    component: 'label',
                    typeface: 'Roboto',
                    styling: 'normal',
                    size: sizing.font.s4,
                    weight: 400,
                    align: 'left',
                    color: palette.accent.neutral.high.main,
                    decoration: 'none',
                    shouldWrap: true,
                    lineHeight: 'normal',
                    stretch: 'normal'
                },
                tooltip: {
                    component: 'span',
                    typeface: 'Roboto',
                    styling: 'normal',
                    size: sizing.font.s4,
                    weight: 400,
                    align: 'left',
                    color: palette.accent.neutral.high.main,
                    decoration: 'none',
                    shouldWrap: true,
                    lineHeight: 'normal',
                    stretch: 'normal'
                },
                monospace: {
                    component: 'span',
                    typeface: 'monospace',
                    styling: 'normal',
                    size: sizing.font.s3,
                    weight: 400,
                    align: 'left',
                    color: palette.accent.neutral.high.main,
                    decoration: 'none',
                    shouldWrap: true,
                    lineHeight: 'normal',
                    stretch: 'normal'
                }
            },
            size: sizing.font
        },

        shape: {
            borderRadius: {
                none: '0',
                small: sizing.fixed.s1,
                medium: sizing.fixed.s2,
                large: sizing.fixed.s4,
                full: '100%'
            }
        },

        animation: {
            presets: {
                standard: {
                    mass: 1,
                    tension: 170,
                    friction: 26,
                    precision: 9
                },
                gentle: {
                    mass: 5,
                    tension: 120,
                    friction: 4,
                    precision: 9
                },
                wobbly: {
                    mass: 5,
                    tension: 180,
                    friction: 12,
                    precision: 9
                },
                stiff: {
                    mass: 5,
                    tension: 210,
                    friction: 20,
                    precision: 9
                },
                slow: {
                    mass: 5,
                    tension: 280,
                    friction: 60,
                    precision: 9
                },
                molasses: {
                    mass: 5,
                    tension: 280,
                    friction: 120,
                    precision: 9
                }
            }
        },

        sizing
    };

    cache.set(key, theme);

    return theme;
}

export {createTheme};
