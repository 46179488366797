import {default as styled} from '@emotion/styled';
import type {FC} from 'react';
import {useBreakpoints} from '../../hooks/use-breakpoints/use-breakpoints.js';
import {Hero} from '../hero/hero.js';
import {Stack} from '../stack/stack.js';
import {Teaser} from '../teaser/teaser.js';

const Container = styled('div')({
    width: '100%',
    position: 'relative'
});

interface ILineProps {
    corridor: string;
}

const Line = styled('div')<ILineProps>(({corridor}) => ({
    height: '100%',
    width: 3,
    position: 'absolute',
    top: 0,
    left: corridor
}));

const LineTop = styled('div')(
    ({theme}) => ({
        height: '150px',
        width: '100%',
        background: theme.palette.background.contrast.low
    }),
    ({theme}) => ({
        background: `linear-gradient(0deg, ${theme.palette.background.contrast.low} 0%, rgba(255,255,255,0) 100%)`
    })
);

const LineMiddle = styled('div')(({theme}) => ({
    height: 'calc(100% - 300px)',
    width: '100%',
    backgroundColor: theme.palette.background.contrast.low
}));

const LineBottom = styled('div')(
    ({theme}) => ({
        height: '150px',
        width: '100%',
        background: theme.palette.background.contrast.low
    }),
    ({theme}) => ({
        background: `linear-gradient(180deg, ${theme.palette.background.contrast.low} 0%, rgba(255,255,255,0) 100%)`
    })
);

const TopArea: FC = () => {
    const corridor = useBreakpoints({
        base: '80px',
        m: '120px'
    });

    return (
        <Container data-testid={'app-line-area'}>
            <Line corridor={corridor}>
                <LineTop data-no-transition={true} />
                <LineMiddle data-no-transition={true} />
                <LineBottom data-no-transition={true} />
            </Line>
            <Hero corridor={corridor} />
            <Stack corridor={corridor} />
            <Teaser corridor={corridor} />
        </Container>
    );
};

export {TopArea};
