import type {FC} from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {CssBaseline} from './components/css-baseline/css-baseline.js';
import {ToggleThemeType} from './components/toggle-theme-type/toggle-theme-type.js';
import {MediaQueriesStore} from './contexts/media-queries-store/media-queries-store.js';
import {Features} from './layout/features/features.js';
import {Footer} from './layout/footer/footer.js';
import {TopArea} from './layout/top-area/top-area.js';
import {ThemeStore} from './styling/contexts/theme-store/theme-store.js';

const App: FC = () => {
    return (
        <ThemeStore defaultType={'auto'}>
            <MediaQueriesStore>
                <CssBaseline />

                <TopArea />

                <ThemeStore invert={true}>
                    <Features />
                </ThemeStore>

                <Footer />

                <ToggleThemeType />
            </MediaQueriesStore>
        </ThemeStore>
    );
};

export {App};
