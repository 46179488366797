import {default as styled} from '@emotion/styled';
import {alpha} from '@mui/system';
import type {FC, ReactNode} from 'react';
import type {ITextProps} from '../text/text.js';
import {Text} from '../text/text.js';

const Container = styled(Text)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: theme.shape.borderRadius.full,
    position: 'relative',
    '& > svg': {
        zIndex: theme.zIndex.local.top
    },
    '&::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: '150%',
        width: '150%',
        borderRadius: theme.shape.borderRadius.full,
        zIndex: theme.zIndex.local.highest,
        transition: 'background linear 150ms'
    },
    '&:hover::after': {
        backgroundColor: alpha(theme.palette.accent.primary.high.main, 0.2)
    }
}));

interface IButtonIconProps extends ITextProps {
    children: ReactNode;
}

const ButtonIcon: FC<IButtonIconProps> = ({children, ...rest}) => {
    return (
        <Container variant={'span'} {...rest}>
            {children}
        </Container>
    );
};

export type {IButtonIconProps};
export {ButtonIcon};
