import {default as styled} from '@emotion/styled';
import type {FC} from 'react';
// import {AnalyticsIcon} from '../../assets/icons/analytics-icon.js';
// import {CalendarIcon} from '../../assets/icons/calendar-icon.js';
import {ChecklistIcon} from '../../assets/icons/checklist-icon.js';
import {ClockIcon} from '../../assets/icons/clock-icon.js';
import {CouponIcon} from '../../assets/icons/coupon-icon.js';
// import {DecentralizedIcon} from '../../assets/icons/decentralized-icon.js';
import {GraphIcon} from '../../assets/icons/graph-icon.js';
// import {IrisScanIcon} from '../../assets/icons/iris-scan-icon.js';
import {MagazineIcon} from '../../assets/icons/magazine-icon.js';
import {MoreIcon} from '../../assets/icons/more-icon.js';
// import {NotificationAlertIcon} from '../../assets/icons/notification-alert-icon.js';
import {OTPIcon} from '../../assets/icons/otp-icon.js';
import {SmartIcon} from '../../assets/icons/smart-icon.js';
// import {StopwatchIcon} from '../../assets/icons/stopwatch-icon.js';
import {StorageIcon} from '../../assets/icons/storage-icon.js';
import {SubscriptionIcon} from '../../assets/icons/subscription-icon.js';
import {TruckIcon} from '../../assets/icons/truck-icon.js';
// import {WarningIcon} from '../../assets/icons/warning-icon.js';
import {DashboardViewSvg} from '../../assets/svg/dashboard-view-svg.js';
import {EmailsListSvg} from '../../assets/svg/emails-list-svg.js';
import {FromToSvg} from '../../assets/svg/from-to-svg.js';
import {TasksListSvg} from '../../assets/svg/tasks-list-svg.js';
import {CardSideA} from '../../components/card-side-a/card-side-a.js';
import {CardSideB} from '../../components/card-side-b/card-side-b.js';
import {CardsContainer} from '../../components/cards-container/cards-container.js';
import {InteractiveCard} from '../../components/interactive-card/interactive-card.js';
import {Text} from '../../components/text/text.js';
import {TextList} from '../../components/text-list/text-list.js';

const Container = styled('div')(({theme}) => ({
    width: '100%',
    minHeight: '100vh',
    backgroundColor: theme.palette.background.normal,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: theme.elevation.mid,
    paddingBottom: theme.sizing.layout.s6
}));

interface IInformationProps {
    jump?: boolean;
}

const Information = styled('div')<IInformationProps>(({theme, jump}) => ({
    padding: jump ? `${theme.sizing.layout.s7} 10%` : '0px 10%'
}));

const Graphics = styled('div')(({theme}) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.sizing.layout.s4,
    margin: '80px 0px',
    [theme.query.m]: {
        flexDirection: 'row',
        justifyContent: 'center'
    }
}));

const FromToContainer = styled('div')(({theme}) => ({
    transform: 'rotate(90deg)',
    [theme.query.m]: {
        transform: 'rotate(0deg)'
    }
}));

const Features: FC = () => {
    return (
        <Container>
            <Information jump={true}>
                <Text variant={'h1'} size={'s9'}>
                    {'Introducing Lettergy'}
                </Text>

                <Text variant={'p'} size={'s5'}>
                    {
                        'Remastering email services to center around you and your time. Lettergy guides decision-making processes with intuitive features that highlight critical insights and suggest consequent steps. With an innovative task-driven approach, it filters out the irrelevant and elevates everything that matters thorough multiple levels of data abstraction.'
                    }
                </Text>
            </Information>

            <Information>
                <Text variant={'h2'} size={'s7'}>
                    {'Some Lettergy Abstractions'}
                </Text>
            </Information>

            <CardsContainer>
                <InteractiveCard
                    sideA={
                        <CardSideA
                            icon={<OTPIcon width={'80px'} height={'80px'} />}
                            title={'One-click Email Address Verification'}
                        />
                    }
                    sideB={
                        <CardSideB
                            content={
                                'Be in control every time a third-party requests to verify your email address, such as during website registrations. Receive a prompt notification and approve or decline with a single click.'
                            }
                        />
                    }
                />

                <InteractiveCard
                    sideA={
                        <CardSideA
                            icon={
                                <SubscriptionIcon
                                    width={'80px'}
                                    height={'80px'}
                                />
                            }
                            title={'Subscriptions Management'}
                        />
                    }
                    sideB={
                        <CardSideB
                            content={
                                'Manage all your subscriptions effortlessly. Lettergy gathers information about all your current feed subscriptions, allowing to cancel and resume as needed without even leaving the app.'
                            }
                        />
                    }
                />

                <InteractiveCard
                    sideA={
                        <CardSideA
                            icon={
                                <MagazineIcon width={'80px'} height={'80px'} />
                            }
                            title={'Newsletters to Magazine'}
                        />
                    }
                    sideB={
                        <CardSideB
                            content={
                                'Condense pertinent information from your subscriptions into succinct, AI-curated magazines. Set your preferred pace and delivery time for a personalized reading experience.'
                            }
                        />
                    }
                />

                <InteractiveCard
                    sideA={
                        <CardSideA
                            icon={
                                <StorageIcon width={'80px'} height={'80px'} />
                            }
                            title={'File Management'}
                        />
                    }
                    sideB={
                        <CardSideB
                            content={
                                'Easily organize and retrieve files attached in emails with AI-enhanced sorting and searching capabilities. Integrate with third party storage services.'
                            }
                        />
                    }
                />

                <InteractiveCard
                    sideA={
                        <CardSideA
                            icon={<CouponIcon width={'80px'} height={'80px'} />}
                            title={'Never Miss a Coupon'}
                        />
                    }
                    sideB={
                        <CardSideB
                            content={
                                'Keep track of all active coupons and discounts in a sorted list with timely alerts. After one expires, the corresponding email will be automatically removed.'
                            }
                        />
                    }
                />

                <InteractiveCard
                    sideA={
                        <CardSideA
                            icon={<TruckIcon width={'80px'} height={'80px'} />}
                            title={'Deliveries Tracking'}
                        />
                    }
                    sideB={
                        <CardSideB
                            content={
                                'Hassle-free parcel tracking with real-time updates right in your main dashboard.'
                            }
                        />
                    }
                />

                <InteractiveCard
                    sideA={
                        <CardSideA
                            icon={<ClockIcon width={'80px'} height={'80px'} />}
                            title={'Smart Throttling'}
                        />
                    }
                    sideB={
                        <CardSideB
                            content={
                                'Lettergy will manage your email flow efficiently by prioritizing important information and suppressing less urgent one during peak hours.'
                            }
                        />
                    }
                />

                <InteractiveCard
                    sideA={
                        <CardSideA
                            icon={
                                <ChecklistIcon width={'80px'} height={'80px'} />
                            }
                            title={'Reminders Collection'}
                        />
                    }
                    sideB={
                        <CardSideB
                            content={
                                'Automated deadline tracking and reminders ensure you stay ahead of your responsibilities.'
                            }
                        />
                    }
                />

                <InteractiveCard
                    sideA={
                        <CardSideA
                            icon={<GraphIcon width={'80px'} height={'80px'} />}
                            title={'Financial Reports'}
                        />
                    }
                    sideB={
                        <CardSideB
                            content={
                                'Receive concise financial summaries and insights from received bills and other transactional emails for informed decision-making.'
                            }
                        />
                    }
                />

                <InteractiveCard
                    sideA={
                        <CardSideA
                            icon={<SmartIcon width={'80px'} height={'80px'} />}
                            title={'Smart Actions'}
                        />
                    }
                    sideB={
                        <CardSideB
                            content={
                                'Tackle priorities swiftly with AI analyzing email contents and suggesting optimal actions based on your historical behavior.'
                            }
                        />
                    }
                />

                {/*<InteractiveCard*/}
                {/*    sideA={*/}
                {/*        <CardSideA*/}
                {/*            icon={*/}
                {/*                <NotificationAlertIcon*/}
                {/*                    width={'80px'}*/}
                {/*                    height={'80px'}*/}
                {/*                />*/}
                {/*            }*/}
                {/*            title={'Native Notifications Converter'}*/}
                {/*        />*/}
                {/*    }*/}
                {/*    sideB={*/}
                {/*        <CardSideB*/}
                {/*            content={*/}
                {/*                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.'*/}
                {/*            }*/}
                {/*        />*/}
                {/*    }*/}
                {/*/>*/}

                {/*<InteractiveCard*/}
                {/*    sideA={*/}
                {/*        <CardSideA*/}
                {/*            icon={*/}
                {/*                <AnalyticsIcon*/}
                {/*                    width={'80px'}*/}
                {/*                    height={'80px'}*/}
                {/*                />*/}
                {/*            }*/}
                {/*            title={'Comprehensive Analytics'}*/}
                {/*        />*/}
                {/*    }*/}
                {/*    sideB={*/}
                {/*        <CardSideB*/}
                {/*            content={*/}
                {/*                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.'*/}
                {/*            }*/}
                {/*        />*/}
                {/*    }*/}
                {/*/>*/}

                {/*<InteractiveCard*/}
                {/*    sideA={*/}
                {/*        <CardSideA*/}
                {/*            icon={*/}
                {/*                <IrisScanIcon*/}
                {/*                    width={'80px'}*/}
                {/*                    height={'80px'}*/}
                {/*                />*/}
                {/*            }*/}
                {/*            title={'AI Security'}*/}
                {/*        />*/}
                {/*    }*/}
                {/*    sideB={*/}
                {/*        <CardSideB*/}
                {/*            content={*/}
                {/*                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.'*/}
                {/*            }*/}
                {/*        />*/}
                {/*    }*/}
                {/*/>*/}

                {/*<InteractiveCard*/}
                {/*    sideA={*/}
                {/*        <CardSideA*/}
                {/*            icon={*/}
                {/*                <StopwatchIcon*/}
                {/*                    width={'80px'}*/}
                {/*                    height={'80px'}*/}
                {/*                />*/}
                {/*            }*/}
                {/*            title={'Disposable Emails'}*/}
                {/*        />*/}
                {/*    }*/}
                {/*    sideB={*/}
                {/*        <CardSideB*/}
                {/*            content={*/}
                {/*                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.'*/}
                {/*            }*/}
                {/*        />*/}
                {/*    }*/}
                {/*/>*/}

                {/*<InteractiveCard*/}
                {/*    sideA={*/}
                {/*        <CardSideA*/}
                {/*            icon={*/}
                {/*                <DecentralizedIcon*/}
                {/*                    width={'80px'}*/}
                {/*                    height={'80px'}*/}
                {/*                />*/}
                {/*            }*/}
                {/*            title={'Distributed Ratings'}*/}
                {/*        />*/}
                {/*    }*/}
                {/*    sideB={*/}
                {/*        <CardSideB*/}
                {/*            content={*/}
                {/*                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.'*/}
                {/*            }*/}
                {/*        />*/}
                {/*    }*/}
                {/*/>*/}

                {/*<InteractiveCard*/}
                {/*    sideA={*/}
                {/*        <CardSideA*/}
                {/*            icon={*/}
                {/*                <CalendarIcon*/}
                {/*                    width={'80px'}*/}
                {/*                    height={'80px'}*/}
                {/*                />*/}
                {/*            }*/}
                {/*            title={'Smart Calendar'}*/}
                {/*        />*/}
                {/*    }*/}
                {/*    sideB={*/}
                {/*        <CardSideB*/}
                {/*            content={*/}
                {/*                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.'*/}
                {/*            }*/}
                {/*        />*/}
                {/*    }*/}
                {/*/>*/}

                {/*<InteractiveCard*/}
                {/*    sideA={*/}
                {/*        <CardSideA*/}
                {/*            icon={*/}
                {/*                <WarningIcon width={'80px'} height={'80px'} />*/}
                {/*            }*/}
                {/*            title={'Native Critical Alerts'}*/}
                {/*        />*/}
                {/*    }*/}
                {/*    sideB={*/}
                {/*        <CardSideB*/}
                {/*            content={*/}
                {/*                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.'*/}
                {/*            }*/}
                {/*        />*/}
                {/*    }*/}
                {/*/>*/}

                <InteractiveCard
                    sideA={
                        <CardSideA
                            icon={<MoreIcon width={'80px'} height={'80px'} />}
                            title={'Discover More!'}
                        />
                    }
                    sideB={
                        <CardSideB
                            content={
                                'Join us at the Web Summit to uncover a myriad of upcoming features and enhancements!'
                            }
                        />
                    }
                />
            </CardsContainer>

            <Information jump={true}>
                <Text variant={'h2'} size={'s7'}>
                    {'A Complete Shift of Perspective'}
                </Text>

                <Text variant={'h5'} size={'s5'} margin={'s0'}>
                    {'Never Seen Task Based Experience'}
                </Text>

                <Graphics>
                    <EmailsListSvg />
                    <FromToContainer>
                        <FromToSvg />
                    </FromToContainer>
                    <TasksListSvg />
                </Graphics>

                <Text variant={'p'} size={'s5'}>
                    {
                        'With every email comes a task and Lettergy will help you identifying it! It will think ahead what is key to do next. No assistant has ever been so precise at recapping the main information and suggesting actions aligned to you. Your uniqueness is our center, all the experience is designed to fit your goals, while saving you a lot of time.'
                    }
                </Text>
            </Information>

            <Information>
                <Text variant={'h2'} size={'s7'}>
                    {'Elevating to Dashboards'}
                </Text>

                <Text variant={'h5'} size={'s5'} margin={'s0'}>
                    {'Just What You Need, When You Need It'}
                </Text>

                <Graphics>
                    <DashboardViewSvg />
                </Graphics>

                <Text variant={'p'} size={'s5'}>
                    {
                        'Experience effortless information assimilation with graphically rich dashboards, where you determine the display of processed data, tailoring it to your daily requirements or special occasions. Let Lettergy fulfill its minimum effort promise, taking over selection duties when you prefer a hands-off approach.'
                    }
                </Text>
            </Information>

            <Information jump={true}>
                <Text variant={'h2'} size={'s7'}>
                    {'Soft Features'}
                </Text>

                <TextList
                    items={[
                        [
                            'Lettergy Learns: ',
                            'Constantly evolving, Lettergy adapts to your habits and preferences, enhancing its utility with every interaction.'
                        ],
                        [
                            'Co-pilot: ',
                            'Receive assistance while composing emails with dynamic suggestions to streamline your workflow.'
                        ],
                        [
                            'Automatic Cleanup: ',
                            'Maintain a clean and organized inbox with periodic removal of expired or unnecessary emails.'
                        ],
                        [
                            'Unified Experience: ',
                            'Lettergy can handle all your email accounts at once, avoiding any mix-ups.'
                        ],
                        [
                            'Seamless Management: ',
                            'Facilitate easy migration and long-term backup of important data with a few simple clicks.'
                        ]
                    ]}
                />
            </Information>
        </Container>
    );
};

export {Features};
