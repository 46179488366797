import type {FC} from 'react';
import type {IIconProps} from '../../defines/common.types.js';

const GraphIcon: FC<IIconProps> = (props) => {
    return (
        <svg
            xmlns={'http://www.w3.org/2000/svg'}
            xmlnsXlink={'http://www.w3.org/1999/xlink'}
            viewBox={'0 0 64 64'}
            width={'24px'}
            height={'24px'}
            {...props}
        >
            <path
                d={
                    'M55,18.057h-4c-0.551,0-1,0.449-1,1s0.449,1,1,1h4c0.551,0,1-0.449,1-1S55.551,18.057,55,18.057z'
                }
            />
            <path
                d={
                    'M57,8.057H7c-1.656,0-3,1.344-3,3v42c0,1.656,1.344,3,3,3h50c1.656,0,3-1.344,3-3v-42C60,9.4,58.656,8.057,57,8.057z M58,53.057c0,0.551-0.449,1-1,1H7c-0.551,0-1-0.449-1-1v-33h41c0.551,0,1-0.449,1-1s-0.449-1-1-1H6v-7c0-0.551,0.449-1,1-1h50c0.551,0,1,0.449,1,1V53.057z'
                }
            />
            <path
                d={
                    'M20,12.057c-0.551,0-1,0.449-1,1v2c0,0.551,0.449,1,1,1s1-0.449,1-1v-2C21,12.506,20.551,12.057,20,12.057z'
                }
            />
            <path
                d={
                    'M15,12.057c-0.551,0-1,0.449-1,1v2c0,0.551,0.449,1,1,1s1-0.449,1-1v-2C16,12.506,15.551,12.057,15,12.057z'
                }
            />
            <path
                d={
                    'M10,12.057c-0.551,0-1,0.449-1,1v2c0,0.551,0.449,1,1,1s1-0.449,1-1v-2C11,12.506,10.551,12.057,10,12.057z'
                }
            />
            <path
                d={
                    'M35,12.057c-0.551,0-1,0.449-1,1v2c0,0.551,0.449,1,1,1s1-0.449,1-1v-2C36,12.506,35.551,12.057,35,12.057z'
                }
            />
            <path
                d={
                    'M40,12.057c-0.551,0-1,0.449-1,1v2c0,0.551,0.449,1,1,1s1-0.449,1-1v-2C41,12.506,40.551,12.057,40,12.057z'
                }
            />
            <path
                d={
                    'M45,12.057c-0.551,0-1,0.449-1,1v2c0,0.551,0.449,1,1,1s1-0.449,1-1v-2C46,12.506,45.551,12.057,45,12.057z'
                }
            />
            <path
                d={
                    'M50,16.057c0.551,0,1-0.449,1-1v-2c0-0.551-0.449-1-1-1s-1,0.449-1,1v2C49,15.607,49.449,16.057,50,16.057z'
                }
            />
            <path
                d={
                    'M55,12.057c-0.551,0-1,0.449-1,1v2c0,0.551,0.449,1,1,1s1-0.449,1-1v-2C56,12.506,55.551,12.057,55,12.057z'
                }
            />
            <path
                d={
                    'M25,12.057c-0.551,0-1,0.449-1,1v2c0,0.551,0.449,1,1,1s1-0.449,1-1v-2C26,12.506,25.551,12.057,25,12.057z'
                }
            />
            <path
                d={
                    'M30,12.057c-0.551,0-1,0.449-1,1v2c0,0.551,0.449,1,1,1s1-0.449,1-1v-2C31,12.506,30.551,12.057,30,12.057z'
                }
            />
            <path
                d={
                    'M15,42.057c-1.654,0-3-1.346-3-3s1.346-3,3-3s3,1.346,3,3S16.654,42.057,15,42.057z M15,38.057c-0.551,0-1,0.448-1,1s0.449,1,1,1s1-0.448,1-1S15.551,38.057,15,38.057z'
                }
            />
            <path
                d={
                    'M26,46.057c-1.654,0-3-1.346-3-3s1.346-3,3-3s3,1.346,3,3S27.654,46.057,26,46.057z M26,42.057c-0.551,0-1,0.448-1,1s0.449,1,1,1s1-0.448,1-1S26.551,42.057,26,42.057z'
                }
            />
            <path
                d={
                    'M36,37.057c-1.654,0-3-1.346-3-3s1.346-3,3-3s3,1.346,3,3S37.654,37.057,36,37.057z M36,33.057c-0.551,0-1,0.448-1,1s0.449,1,1,1s1-0.448,1-1S36.551,33.057,36,33.057z'
                }
            />
            <path
                d={
                    'M46,43.057c-1.654,0-3-1.346-3-3s1.346-3,3-3s3,1.346,3,3S47.654,43.057,46,43.057z M46,39.057c-0.551,0-1,0.448-1,1s0.449,1,1,1s1-0.448,1-1S46.551,39.057,46,39.057z'
                }
            />
            <path
                d={
                    'M9,46.057c-0.256,0-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414l4-4c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414l-4,4C9.512,45.959,9.256,46.057,9,46.057z'
                }
            />
            <path
                d={
                    'M23.805,43.259c-0.114,0-0.229-0.02-0.342-0.061l-6.633-2.412c-0.519-0.188-0.787-0.762-0.598-1.281c0.189-0.52,0.766-0.784,1.282-0.598l6.633,2.412c0.519,0.188,0.787,0.762,0.598,1.281C24.597,43.007,24.214,43.259,23.805,43.259z'
                }
            />
            <path
                d={
                    'M27.778,42.457c-0.274,0-0.546-0.111-0.744-0.331c-0.37-0.41-0.336-1.043,0.074-1.412l6.427-5.784c0.411-0.369,1.042-0.336,1.412,0.074s0.336,1.043-0.074,1.412L28.446,42.2C28.255,42.372,28.016,42.457,27.778,42.457z'
                }
            />
            <path
                d={
                    'M43.814,39.745c-0.175,0-0.353-0.046-0.514-0.143l-5.815-3.488c-0.473-0.284-0.627-0.898-0.343-1.372c0.284-0.475,0.897-0.628,1.372-0.343l5.815,3.488c0.473,0.284,0.627,0.898,0.343,1.372C44.485,39.572,44.154,39.745,43.814,39.745z'
                }
            />
            <path
                d={
                    'M47.708,39.349c-0.256,0-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414l7.292-7.292c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414l-7.292,7.292C48.22,39.251,47.964,39.349,47.708,39.349z'
                }
            />
        </svg>
    );
};

export {GraphIcon};
