import type {FC} from 'react';
import type {IIconProps} from '../../defines/common.types.js';

const SmartIcon: FC<IIconProps> = (props) => {
    return (
        <svg
            xmlns={'http://www.w3.org/2000/svg'}
            xmlnsXlink={'http://www.w3.org/1999/xlink'}
            viewBox={'0 0 50 50'}
            width={'24px'}
            height={'24px'}
            {...props}
        >
            <path
                style={{
                    fill: 'none',
                    stroke: 'currentcolor',
                    strokeWidth: 2,
                    strokeMiterlimit: 10
                }}
                d={
                    'M33,48v-4h2.886C39.815,44,43,40.815,43,36.886V32l2.548-1.07c1.249-0.525,1.765-2.018,1.107-3.202l-4.192-7.536C41.435,11.576,36.858,3,24,3C11.789,3,4,11.762,4,21.403c0,9.095,7,14.442,7,22.009V48'
                }
            />
            <path
                style={{
                    fill: 'none',
                    stroke: 'currentcolor',
                    strokeWidth: 2,
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeMiterlimit: 10
                }}
                d={
                    'M25,39h-2c-1.105,0-2-0.895-2-2v-2h6v2C27,38.105,26.105,39,25,39z'
                }
            />
            <path d={'M26,39h-4c0,1.105,0.895,2,2,2S26,40.105,26,39z'} />
            <path
                style={{
                    fill: 'none',
                    stroke: 'currentcolor',
                    strokeWidth: 2,
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeMiterlimit: 10
                }}
                d={
                    'M33,23c0-4.971-4.029-9-9-9s-9,4.029-9,9c0,3.473,1.97,6.481,4.851,7.982C20.467,32.088,21,33.297,21,35h6c0-1.721,0.544-2.923,1.168-4.027C31.038,29.469,33,26.466,33,23z'
                }
            />
            <path
                style={{
                    fill: 'none',
                    stroke: 'currentcolor',
                    strokeWidth: 2,
                    strokeLinejoin: 'round',
                    strokeMiterlimit: 10
                }}
                d={'M24,32v-7c0-1.105,0.895-2,2-2h0.5'}
            />
            <path
                style={{
                    fill: 'none',
                    stroke: 'currentcolor',
                    strokeWidth: 2,
                    strokeLinejoin: 'round',
                    strokeMiterlimit: 10
                }}
                d={'M24,25c0-1.105-0.895-2-2-2h-0.5'}
            />
            <circle cx={'26.5'} cy={'23.5'} r={'1.5'} />
            <circle cx={'21.5'} cy={'23.5'} r={'1.5'} />
        </svg>
    );
};

export {SmartIcon};
