import {Global, useTheme} from '@emotion/react';
import type {FC} from 'react';

const CssBaseline: FC = () => {
    const {palette} = useTheme();

    return (
        <Global
            styles={{
                'html, body': {
                    boxSizing: 'border-box',
                    margin: 0,
                    padding: 0,
                    backgroundColor: palette.background.normal,
                    WebkitFontSmoothing: 'antialiased',
                    MozOsxFontSmoothing: 'grayscale'
                },
                '*': {
                    boxSizing: 'border-box'
                },
                'html, body, div:not([data-no-transition]), span:not([data-no-transition])':
                    {
                        transition:
                            'background 300ms linear, color 300ms linear'
                    }
            }}
        />
    );
};

export {CssBaseline};
