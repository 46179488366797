import type {IAllTokens, TBaseColors} from '../../defines/theme.types.js';
import {elevation, opacity, sizing} from './defines/default-tokens.js';

interface IOverrides extends Partial<IAllTokens> {
    tonal: IAllTokens['tonal'];
}

function createTokens(
    baseColors: TBaseColors,
    overrides: IOverrides
): IAllTokens {
    return {
        tonal: overrides.tonal,
        opacity: overrides.opacity || opacity,
        elevation: overrides.elevation || elevation,
        sizing: overrides.sizing || sizing
    };
}

export {createTokens};
