import type {
    IBackground,
    ILink,
    IPalette,
    ISurface,
    TColorTokenKey,
    TThemeType,
    TTonals
} from '../../defines/theme.types.js';
import {createAccent} from '../create-accent/create-accent.js';

type TMap = {
    [k in TThemeType]: {
        background: IBackground<TColorTokenKey>;
        surface: ISurface<TColorTokenKey>;
        link: ILink<TColorTokenKey>;
    };
};

const map: TMap = {
    light: {
        background: {
            dim: '87',
            normal: '98',
            bright: '98',
            backdrop: '0',
            contrast: {
                high: '10',
                low: '30',
                disabled: '50'
            }
        },
        surface: {
            lowest: '100',
            low: '96',
            mid: '94',
            high: '92',
            highest: '90',
            contrast: {
                high: '10',
                low: '30',
                disabled: '50'
            }
        },
        link: {
            idle: '20',
            hover: '20',
            visited: '0',
            disabled: '80'
        }
    },
    dark: {
        background: {
            dim: '6',
            normal: '6',
            bright: '24',
            backdrop: '0',
            contrast: {
                high: '90',
                low: '70',
                disabled: '50'
            }
        },
        surface: {
            lowest: '4',
            low: '10',
            mid: '12',
            high: '17',
            highest: '22',
            contrast: {
                high: '90',
                low: '70',
                disabled: '50'
            }
        },
        link: {
            idle: '90',
            hover: '90',
            visited: '0',
            disabled: '80'
        }
    }
};

function createPalette(tonal: TTonals, type: TThemeType): IPalette {
    const {background, surface, link} = map[type];

    return {
        accent: {
            primary: createAccent(tonal, 'primary', type),
            secondary: createAccent(tonal, 'secondary', type),
            tertiary: createAccent(tonal, 'tertiary', type),
            neutral: createAccent(tonal, 'neutral', type),
            disabled: createAccent(tonal, 'disabled', type),
            error: createAccent(tonal, 'error', type),
            warning: createAccent(tonal, 'warning', type),
            success: createAccent(tonal, 'success', type),
            info: createAccent(tonal, 'info', type)
        },
        background: {
            dim: tonal.neutral[background.dim],
            normal: tonal.neutral[background.normal],
            bright: tonal.neutral[background.bright],
            backdrop: tonal.neutral[background.backdrop],
            contrast: {
                high: tonal.neutral[background.contrast.high],
                low: tonal.divergent[background.contrast.low],
                disabled: tonal.divergent[background.contrast.disabled]
            }
        },
        surface: {
            lowest: tonal.neutral[surface.lowest],
            low: tonal.neutral[surface.low],
            mid: tonal.neutral[surface.mid],
            high: tonal.neutral[surface.high],
            highest: tonal.neutral[surface.highest],
            contrast: {
                high: tonal.neutral[surface.contrast.high],
                low: tonal.divergent[surface.contrast.low],
                disabled: tonal.divergent[surface.contrast.disabled]
            }
        },
        link: {
            idle: tonal.neutral[link.idle],
            hover: tonal.neutral[link.hover],
            visited: tonal.neutral[link.visited],
            disabled: tonal.neutral[link.disabled]
        }
    };
}

export {createPalette};
