import type {FC} from 'react';
import type {IIconProps} from '../../defines/common.types.js';

const ChecklistIcon: FC<IIconProps> = (props) => {
    return (
        <svg
            xmlns={'http://www.w3.org/2000/svg'}
            xmlnsXlink={'http://www.w3.org/1999/xlink'}
            viewBox={'0 0 50 50'}
            width={'24px'}
            height={'24px'}
            {...props}
        >
            <rect
                x={'1'}
                y={'1'}
                style={{
                    fill: 'none',
                    stroke: 'currentcolor',
                    strokeWidth: 2,
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeMiterlimit: 10
                }}
                width={'12'}
                height={'12'}
            />
            <line
                style={{
                    fill: 'none',
                    stroke: 'currentcolor',
                    strokeWidth: 1.9654,
                    strokeLinejoin: 'round',
                    strokeMiterlimit: 10
                }}
                x1={'20'}
                y1={'7'}
                x2={'50'}
                y2={'7'}
            />
            <polyline
                style={{
                    fill: 'none',
                    stroke: 'currentcolor',
                    strokeWidth: 2,
                    strokeMiterlimit: 10
                }}
                points={'11,4 6.5,9 3,6 '}
            />
            <rect
                x={'1'}
                y={'19'}
                style={{
                    fill: 'none',
                    stroke: 'currentcolor',
                    strokeWidth: 2,
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeMiterlimit: 10
                }}
                width={'12'}
                height={'12'}
            />
            <line
                style={{
                    fill: 'none',
                    stroke: 'currentcolor',
                    strokeWidth: 1.9654,
                    strokeLinejoin: 'round',
                    strokeMiterlimit: 10
                }}
                x1={'20'}
                y1={'25'}
                x2={'50'}
                y2={'25'}
            />
            <polyline
                style={{
                    fill: 'none',
                    stroke: 'currentcolor',
                    strokeWidth: 2,
                    strokeMiterlimit: 10
                }}
                points={'11,22 6.5,27 3,24 '}
            />
            <rect
                x={'1'}
                y={'37'}
                style={{
                    fill: 'none',
                    stroke: 'currentcolor',
                    strokeWidth: 2,
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeMiterlimit: 10
                }}
                width={'12'}
                height={'12'}
            />
            <line
                style={{
                    fill: 'none',
                    stroke: 'currentcolor',
                    strokeWidth: 1.9654,
                    strokeLinejoin: 'round',
                    strokeMiterlimit: 10
                }}
                x1={'20'}
                y1={'43'}
                x2={'50'}
                y2={'43'}
            />
        </svg>
    );
};

export {ChecklistIcon};
