import type {FC} from 'react';
import type {IIconProps} from '../../defines/common.types.js';

const StorageIcon: FC<IIconProps> = (props) => {
    return (
        <svg
            xmlns={'http://www.w3.org/2000/svg'}
            xmlnsXlink={'http://www.w3.org/1999/xlink'}
            viewBox={'0 0 50 50'}
            width={'24px'}
            height={'24px'}
            {...props}
        >
            <circle
                style={{
                    fill: 'none',
                    stroke: 'currentcolor',
                    strokeWidth: 2,
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeMiterlimit: 10
                }}
                cx={'25'}
                cy={'25'}
                r={'22'}
            />
            <circle
                style={{
                    fill: 'none',
                    stroke: 'currentcolor',
                    strokeWidth: 2,
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeMiterlimit: 10
                }}
                cx={'25'}
                cy={'25'}
                r={'12'}
            />
            <line
                style={{
                    fill: 'none',
                    stroke: 'currentcolor',
                    strokeWidth: 2,
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeMiterlimit: 10
                }}
                x1={'25'}
                y1={'12.565'}
                x2={'25'}
                y2={'3'}
            />
            <line
                style={{
                    fill: 'none',
                    stroke: 'currentcolor',
                    strokeWidth: 2,
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeMiterlimit: 10
                }}
                x1={'33.792'}
                y1={'33.792'}
                x2={'40.556'}
                y2={'40.556'}
            />
        </svg>
    );
};

export {StorageIcon};
