import {useTheme} from '@emotion/react';
import {default as styled} from '@emotion/styled';
import type {FC, ReactNode} from 'react';
import {useMemo} from 'react';
import type {
    ITheme,
    ITypographyDescriptor,
    TAccentKey,
    TEmphasisKey,
    TTypographyKey
} from '../../styling/defines/theme.types.js';

type TBaseContainerProps = Omit<ITypographyDescriptor, 'component'>;

interface IContainerProps extends TBaseContainerProps {
    margin: string | number | undefined;
}

const Container = styled('p')<IContainerProps>(
    ({
        typeface,
        styling,
        size,
        weight,
        align,
        color,
        decoration,
        shouldWrap,
        lineHeight,
        stretch,
        margin
    }) => ({
        fontFamily: typeface,
        fontStyle: styling,
        fontSize: size,
        fontWeight: weight,
        textAlign: align,
        color: color,
        textDecoration: decoration,
        whiteSpace: shouldWrap ? 'normal' : 'nowrap',
        lineHeight: lineHeight,
        fontStretch: stretch,
        margin: margin,

        '& svg': {
            color,
            fill: color
        }
    })
);

type TSizeKey = keyof ITheme['typography']['size'];

interface IMargin {
    top?: TSizeKey;
    right?: TSizeKey;
    bottom?: TSizeKey;
    left?: TSizeKey;
}

type TBaseProps = Partial<ITypographyDescriptor>;

interface ITextProps extends TBaseProps {
    variant?: TTypographyKey;
    size?: TSizeKey;
    color?: TAccentKey;
    emphasis?: TEmphasisKey;
    margin?: TSizeKey | IMargin;
    children: ReactNode;
}

const Text: FC<ITextProps> = ({
    variant = 'p',
    component,
    typeface,
    styling,
    size,
    weight,
    align,
    color,
    emphasis = 'high',
    decoration,
    shouldWrap,
    lineHeight,
    stretch,
    margin,
    children,
    ...rest
}) => {
    const {typography, palette} = useTheme();

    const base = typography.variant[variant];

    const Component = component || base.component;

    const m = useMemo<string | undefined>(() => {
        if (!margin) {
            return undefined;
        }

        let k: Required<IMargin>;

        if (typeof margin === 'string') {
            k = {
                top: margin,
                right: margin,
                bottom: margin,
                left: margin
            };
        } else {
            k = {
                top: 's0',
                right: 's0',
                bottom: 's0',
                left: 's0',
                ...margin
            };
        }

        const v = {
            top: typography.size[k.top],
            right: typography.size[k.right],
            bottom: typography.size[k.bottom],
            left: typography.size[k.left]
        };

        return `${v.top} ${v.right} ${v.bottom} ${v.left}`;
    }, [margin, typography.size]);

    return (
        <Container
            {...rest}
            typeface={typeface || base.typeface}
            styling={styling || base.styling}
            size={size ? typography.size[size] : base.size}
            weight={weight || base.weight}
            align={align || base.align}
            color={color ? palette.accent[color][emphasis].main : base.color}
            decoration={decoration || base.decoration}
            shouldWrap={
                typeof shouldWrap === 'boolean' ? shouldWrap : base.shouldWrap
            }
            lineHeight={lineHeight || base.lineHeight}
            stretch={stretch || base.stretch}
            margin={m}
            as={Component}
        >
            {children}
        </Container>
    );
};

export type {ITextProps};
export {Text};
