import type {FC} from 'react';
import type {IIconProps} from '../../defines/common.types.js';

const TruckIcon: FC<IIconProps> = (props) => {
    return (
        <svg
            xmlns={'http://www.w3.org/2000/svg'}
            xmlnsXlink={'http://www.w3.org/1999/xlink'}
            viewBox={'0 0 50 50'}
            width={'24px'}
            height={'24px'}
            {...props}
        >
            <g>
                <circle
                    fill={'none'}
                    stroke={'currentcolor'}
                    strokeWidth={'2'}
                    strokeMiterlimit={'10'}
                    cx={'13'}
                    cy={'44'}
                    r={'5'}
                />
            </g>
            <g>
                <circle
                    fill={'none'}
                    stroke={'currentcolor'}
                    strokeWidth={'2'}
                    strokeMiterlimit={'10'}
                    cx={'39'}
                    cy={'44'}
                    r={'5'}
                />
            </g>
            <path
                fill={'none'}
                stroke={'currentcolor'}
                strokeWidth={'2'}
                strokeMiterlimit={'10'}
                d={
                    'M45,44h2c1.018,0,2-0.982,2-2c0,0,0-7.867,0-9.615c0-1.749-1.414-3.729-1.414-3.729l-4.125-5.502C42.617,22.137,41.355,21,40,21h-8c-1.018,0-2,0.983-2,2v19c0,1.014,0.988,1.992,2,2h2 M49,33c0,0-10,0-11,0s-2-1-2-2c0-0.577,0-2.488,0-4c0-1.107,1-2,2-2s6.846,0,6.846,0'
                }
            />
            <path
                fill={'none'}
                stroke={'currentcolor'}
                strokeWidth={'1.9974'}
                strokeMiterlimit={'10'}
                d={
                    'M18,44h10.154C29.17,44,30,43.173,30,42.158V15.906C30,14.892,29.204,14,28.188,14H2.846C1.829,14,1,14.828,1,15.841v26.164C1,43.02,1.983,44,3,44h5'
                }
            />
        </svg>
    );
};

export {TruckIcon};
