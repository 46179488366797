import type {FC} from 'react';
import type {IIconProps} from '../../defines/common.types.js';

const SubscriptionIcon: FC<IIconProps> = (props) => {
    return (
        <svg
            xmlns={'http://www.w3.org/2000/svg'}
            xmlnsXlink={'http://www.w3.org/1999/xlink'}
            viewBox={'0 0 50 50'}
            width={'24px'}
            height={'24px'}
            {...props}
        >
            <g>
                <polyline
                    style={{
                        fill: 'none',
                        stroke: 'currentcolor',
                        strokeWidth: 2,
                        strokeLinecap: 'round',
                        strokeMiterlimit: 10
                    }}
                    points={'30.097,38 5.041,37.96 5,8 47,8 47,32.072 '}
                />
                <polyline
                    style={{
                        fill: 'none',
                        stroke: 'currentcolor',
                        strokeWidth: 1.9959,
                        strokeMiterlimit: 10
                    }}
                    points={'1,35 1,4 45,4 '}
                />
                <line
                    style={{
                        fill: 'none',
                        stroke: 'currentcolor',
                        strokeWidth: 2,
                        strokeMiterlimit: 10
                    }}
                    x1={'5.097'}
                    y1={'34.419'}
                    x2={'19.396'}
                    y2={'23.112'}
                />
                <path
                    style={{
                        fill: 'none',
                        stroke: 'currentcolor',
                        strokeWidth: 2,
                        strokeMiterlimit: 10
                    }}
                    d={
                        'M5.097,11.576c0,0,16.235,14.15,17.558,15.302c1.323,1.151,2.7,1.416,3.521,1.416s2.199-0.264,3.521-1.415c1.323-1.151,17.558-15.302,17.558-15.302'
                    }
                />
                <path
                    d={
                        'M40,28c-5.5,0-10,4.5-10,10s4.5,10,10,10s10-4.5,10-10S45.5,28,40,28z M40,30c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S35.6,30,40,30z M34.246,37.374l-0.071,0.071c-0.283,0.424-0.212,1.061,0.283,1.414c0.141,0.141,0.354,0.212,0.636,0.212h3.96v3.96c0,0.566,0.424,0.99,0.99,0.99c0.566,0,0.99-0.424,0.99-0.99v-3.96h3.96c0.566,0,0.99-0.424,0.99-0.99s-0.424-0.99-0.99-0.99l-4.031-0.071v-3.96c0-0.566-0.424-0.99-0.919-1.061c-0.283,0-0.707,0.141-0.849,0.424c-0.141,0.141-0.212,0.354-0.212,0.636v3.96h-3.96C34.741,37.02,34.388,37.091,34.246,37.374z'
                    }
                />
            </g>
        </svg>
    );
};

export {SubscriptionIcon};
