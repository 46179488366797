import {default as styled} from '@emotion/styled';
import type {FC, ReactNode} from 'react';

const Container = styled('div')(({theme}) => ({
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.sizing.layout.s6,
    padding: theme.sizing.layout.s6,
    justifyContent: 'center',
    [theme.query.l]: {
        padding: theme.sizing.layout.s8
    }
}));

interface ICardContainerProps {
    children: ReactNode;
}

const CardsContainer: FC<ICardContainerProps> = ({children}) => {
    return <Container>{children}</Container>;
};

export type {ICardContainerProps};
export {CardsContainer};
