import {default as styled} from '@emotion/styled';
import type {FC} from 'react';
import {Text} from '../../components/text/text.js';

interface IContainerProps {
    corridor: string;
}

const Container = styled('div')<IContainerProps>(({theme, corridor}) => ({
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.sizing.layout.s8,
    paddingLeft: corridor,
    marginBottom: theme.sizing.layout.s10
}));

const Content = styled('div')(({theme}) => ({
    margin: `${theme.sizing.layout.s7} 0px`,
    padding: '8%'
}));

interface ITeaserProps {
    corridor: string;
}

const Teaser: FC<ITeaserProps> = ({corridor}) => {
    return (
        <Container corridor={corridor}>
            <Content>
                <Text variant={'h2'} size={'s7'}>
                    {'So...'}
                </Text>

                <Text variant={'p'} size={'s7'}>
                    {
                        'Ever wondered what the next generation of emails will look like?'
                    }
                </Text>
            </Content>
        </Container>
    );
};

export type {ITeaserProps};
export {Teaser};
