import {default as styled} from '@emotion/styled';
import type {FC, ReactNode} from 'react';
import {Text} from '../text/text.js';

interface IContainerProps {
    width: number | string;
    maxWidth: number | string;
    height: number;
    top: number;
    gap: number;
    index: number;
    corridor: string;
}

const Container = styled('div')<IContainerProps>(
    ({theme, width, maxWidth, height, top, gap, index, corridor}) => ({
        width,
        maxWidth,
        height,
        position: 'sticky',
        top: top + index * gap,
        left: corridor,
        zIndex: theme.zIndex.global.mid,
        border: `3px solid ${theme.palette.background.contrast.low}`,
        borderRadius: theme.shape.borderRadius.small,
        backgroundColor: theme.palette.background.normal,
        overflowX: 'visible'
    })
);

interface IProps {
    gap: number;
}

const Label = styled('div')<IProps>(({theme, gap}) => ({
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.sizing.layout.s5,
    height: gap
}));

const Content = styled('div')<IProps>(({gap}) => ({
    display: 'flex',
    height: `calc(100% - ${gap}px)`
}));

const Dot = styled('div')<IProps>(({theme, gap}) => ({
    position: 'absolute',
    top: gap / 2 - 10,
    left: -11.5,
    height: 20,
    width: 20,
    border: `3px solid ${theme.palette.background.contrast.low}`,
    borderRadius: theme.shape.borderRadius.full,
    backgroundColor: theme.palette.background.normal,
    zIndex: theme.zIndex.global.mid
}));

interface ITimeProps {
    gap: number;
    corridor: string;
}

const Time = styled('div')<ITimeProps>(({theme, gap, corridor}) => ({
    position: 'absolute',
    top: gap / 2 - 9.5,
    left: -18,
    width: `calc(${corridor} - 15px)`,
    transform: 'translateX(-100%)',
    zIndex: theme.zIndex.global.mid,
    backgroundColor: theme.palette.background.normal,
    display: 'flex',
    justifyContent: 'flex-end'
}));

interface IStackableCardProps extends IContainerProps {
    label: string;
    content: ReactNode;
    time: string;
}

const StackableCard: FC<IStackableCardProps> = ({
    label,
    content,
    time,
    width,
    maxWidth,
    height,
    top,
    gap,
    index,
    corridor
}) => {
    return (
        <Container
            width={width}
            maxWidth={maxWidth}
            height={height}
            top={top}
            gap={gap}
            index={index}
            corridor={corridor}
        >
            <Label gap={gap}>
                <Text
                    variant={'h4'}
                    margin={'s0'}
                    weight={800}
                    shouldWrap={false}
                >
                    {label}
                </Text>
            </Label>

            <Content gap={gap}>{content}</Content>

            <Dot gap={gap} />

            <Time gap={gap} corridor={corridor}>
                <Text variant={'span'} weight={800}>
                    {time}
                </Text>
            </Time>
        </Container>
    );
};

export type {IStackableCardProps};
export {StackableCard};
