import {default as styled} from '@emotion/styled';
import type {FC} from 'react';
import {LettergyLogoSvg} from '../../assets/svg/lettergy-logo-svg.js';
import {Button} from '../../components/button/button.js';
import {ExternalLink} from '../../components/external-link/external-link.js';
import {useBreakpoints} from '../../hooks/use-breakpoints/use-breakpoints.js';

const WAITING_LIST = process.env.REACT_APP_FEAT_WAITING_LIST === 'true';

const Container = styled('div')({
    position: 'relative',
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});

const ActionArea = styled('div')(({theme}) => ({
    position: 'absolute',
    top: theme.sizing.layout.s8,
    left: theme.sizing.layout.s5
}));

const LinksArea = styled('div')(({theme}) => ({
    position: 'absolute',
    bottom: theme.sizing.layout.s6,
    left: theme.sizing.layout.s6,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.sizing.layout.s5,

    [theme.query.m]: {
        flexDirection: 'row',
        alignItems: 'center'
    }
}));

const Footer: FC = () => {
    const width = useBreakpoints({
        base: '80%',
        l: '700px'
    });

    return (
        <Container>
            <ActionArea>
                {WAITING_LIST ? (
                    <Button>{'Join Waiting List'}</Button>
                ) : (
                    <a href={'mailto:info@lettergy.com'}>
                        <Button>{'Contact Us'}</Button>
                    </a>
                )}
            </ActionArea>

            <LettergyLogoSvg width={width} />

            <LinksArea>
                <ExternalLink
                    label={'Presented on Web Summit 2023'}
                    href={
                        'https://websummit.com/startups/featured-startups?q=eyJxdWVyeSI6ImxldHRlcmd5IiwicGFnZSI6MSwiY29uZmlndXJlIjp7ImhpdHNQZXJQYWdlIjo0OCwidGFnRmlsdGVycyI6WyJ3czIzIl19fQ=='
                    }
                />

                <ExternalLink
                    label={'Engineered by Codistica'}
                    href={'https://www.codistica.com'}
                />
            </LinksArea>
        </Container>
    );
};

export {Footer};
