import type {FC} from 'react';
import type {IIconProps} from '../../defines/common.types.js';

const ArrowOutwardIcon: FC<IIconProps> = (props) => {
    return (
        <svg
            xmlns={'http://www.w3.org/2000/svg'}
            viewBox={'0 0 24 24'}
            width={'24px'}
            height={'24px'}
            {...props}
        >
            <polygon
                points={
                    '7 7 15.586 7 5.293 17.293 6.707 18.707 17 8.414 17 17 19 17 19 5 7 5 7 7'
                }
            />
        </svg>
    );
};

export {ArrowOutwardIcon};
