import {useTheme} from '@emotion/react';
import type {FC} from 'react';
import {useMemo} from 'react';
import type {ISvgProps} from '../../defines/common.types.js';

interface IColors {
    colorA: string;
}

const FromToSvg: FC<ISvgProps> = (props) => {
    const {type} = useTheme();

    const {colorA} = useMemo<IColors>(() => {
        if (type === 'dark') {
            return {
                colorA: '#633f99'
            };
        } else {
            return {
                colorA: '#280056'
            };
        }
    }, [type]);

    return (
        <svg
            width={'50'}
            height={'42'}
            viewBox={'0 0 50 42'}
            fill={'none'}
            xmlns={'http://www.w3.org/2000/svg'}
            {...props}
        >
            <path
                fillRule={'evenodd'}
                clipRule={'evenodd'}
                d={'M31 42L50 21L31 0L31 10H0V32H31V42Z'}
                fill={'url(#paint0_linear_3_183)'}
            />
            <defs>
                <linearGradient
                    id={'paint0_linear_3_183'}
                    x1={'50'}
                    y1={'0.836812'}
                    x2={'2.24996e-07'}
                    y2={'0.836826'}
                    gradientUnits={'userSpaceOnUse'}
                >
                    <stop stopColor={colorA} />
                    <stop offset={'1'} stopColor={colorA} stopOpacity={'0'} />
                </linearGradient>
            </defs>
        </svg>
    );
};

export {FromToSvg};
