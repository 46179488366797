import {default as styled} from '@emotion/styled';
import type {FC, HTMLProps} from 'react';
import {ArrowOutwardIcon} from '../../assets/icons/arrow-outward-icon.js';
import {Text} from '../text/text.js';

const Anchor = styled('a')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.palette.link.idle,
    '& > span': {
        display: 'flex',
        alignItems: 'center'
    },
    '&:hover': {
        textDecoration: 'underline'
    }
}));

const Arrow = styled(ArrowOutwardIcon)(({theme}) => ({
    fill: theme.palette.link.idle,
    marginLeft: theme.sizing.font.s1
}));

type TBaseProps = Partial<
    Omit<HTMLProps<HTMLAnchorElement>, 'target' | 'referrerPolicy'>
>;

interface ITextProps extends TBaseProps {
    label: string;
    href: string;
}

const ExternalLink: FC<ITextProps> = ({label, as, ...rest}) => {
    return (
        <Text variant={'span'}>
            <Anchor {...rest} target={'_blank'} referrerPolicy={'no-referrer'}>
                {label}
                <Arrow />
            </Anchor>
        </Text>
    );
};

export type {ITextProps};
export {ExternalLink};
