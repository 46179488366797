import {default as styled} from '@emotion/styled';
import type {FC} from 'react';
import {Text} from '../text/text.js';

const Container = styled('div')(({theme}) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.sizing.layout.s6
}));

interface ICardSideBProps {
    content: string;
}

const CardSideB: FC<ICardSideBProps> = ({content}) => {
    return (
        <Container>
            <Text size={'s4'} margin={'s0'}>
                {content}
            </Text>
        </Container>
    );
};

export type {ICardSideBProps};
export {CardSideB};
