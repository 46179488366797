import type {FC} from 'react';
import type {IIconProps} from '../../defines/common.types.js';

const MagazineIcon: FC<IIconProps> = (props) => {
    return (
        <svg
            xmlns={'http://www.w3.org/2000/svg'}
            xmlnsXlink={'http://www.w3.org/1999/xlink'}
            viewBox={'0 0 50 50'}
            width={'24px'}
            height={'24px'}
            {...props}
        >
            <path
                style={{
                    fill: 'none',
                    stroke: 'currentcolor',
                    strokeWidth: 2,
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeMiterlimit: 10
                }}
                d={
                    'M3,37c0,0,12.795,4.078,21.273,0L41,2.813C41,2.813,35,7,18,7L3,37z'
                }
            />
            <path
                style={{
                    fill: 'none',
                    stroke: 'currentcolor',
                    strokeWidth: 2,
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeMiterlimit: 10
                }}
                d={'M45.208,9.355L29.189,42.094C13.411,44.835,3,37,3,37'}
            />
            <path
                style={{
                    fill: 'none',
                    stroke: 'currentcolor',
                    strokeWidth: 2,
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeMiterlimit: 10
                }}
                d={'M3,37c0,0,11.588,10,31,10l13.016-26.601'}
            />
        </svg>
    );
};

export {MagazineIcon};
