import type {IThemePack, TThemeType} from '../defines/theme.types.js';
import {STANDARD_THEME_PACK} from './standard/standard.themes.js';

const DEFAULT_THEME_PACK: IThemePack = STANDARD_THEME_PACK;

const ALL_THEME_PACKS: IThemePack[] = [STANDARD_THEME_PACK];

const DEFAULT_THEME_TYPE: TThemeType = 'dark';

const THEME_TYPES: {key: TThemeType}[] = [{key: 'light'}, {key: 'dark'}];

export {
    ALL_THEME_PACKS,
    DEFAULT_THEME_PACK,
    DEFAULT_THEME_TYPE,
    STANDARD_THEME_PACK,
    THEME_TYPES
};
