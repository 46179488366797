import type {TBaseColors, TTonals} from '../../defines/theme.types.js';

const baseColors: TBaseColors = {
    primary: '#B892F2',
    secondary: '#B892F2',
    tertiary: '#9DFFFD',
    neutral: '#150F35',
    divergent: '#150F35',
    disabled: '#777777',
    error: '#FF0000',
    warning: '#F58220',
    success: '#8DC63F',
    info: '#2B8DFE'
};

const tonal: TTonals = {
    primary: {
        '0': '#000000',
        '4': '#170036',
        '6': '#1d0042',
        '10': '#280056',
        '12': '#2d0060',
        '17': '#390f6d',
        '20': '#3f1874',
        '22': '#441e79',
        '24': '#48237d',
        '25': '#4b2680',
        '30': '#57338c',
        '35': '#633f99',
        '40': '#6f4ca6',
        '50': '#8965c1',
        '60': '#a47fdd',
        '70': '#bf99fa',
        '80': '#d6baff',
        '87': '#e6d2ff',
        '90': '#ecdcff',
        '92': '#f1e3ff',
        '94': '#f5eaff',
        '95': '#f8edff',
        '96': '#faf0ff',
        '98': '#fef7ff',
        '99': '#fffbff',
        '100': '#ffffff'
    },
    secondary: {
        '0': '#000000',
        '4': '#170036',
        '6': '#1d0042',
        '10': '#280056',
        '12': '#2d0060',
        '17': '#390f6d',
        '20': '#3f1874',
        '22': '#441e79',
        '24': '#48237d',
        '25': '#4b2680',
        '30': '#57338c',
        '35': '#633f99',
        '40': '#6f4ca6',
        '50': '#8965c1',
        '60': '#a47fdd',
        '70': '#bf99fa',
        '80': '#d6baff',
        '87': '#e6d2ff',
        '90': '#ecdcff',
        '92': '#f1e3ff',
        '94': '#f5eaff',
        '95': '#f8edff',
        '96': '#faf0ff',
        '98': '#fef7ff',
        '99': '#fffbff',
        '100': '#ffffff'
    },
    tertiary: {
        '0': '#000000',
        '4': '#001111',
        '6': '#001717',
        '10': '#002020',
        '12': '#002424',
        '17': '#00302f',
        '20': '#003736',
        '22': '#003c3b',
        '24': '#004140',
        '25': '#004342',
        '30': '#00504f',
        '35': '#005c5c',
        '40': '#006a69',
        '50': '#008584',
        '60': '#00a1a0',
        '70': '#1fbebd',
        '80': '#4ddad8',
        '87': '#65eeec',
        '90': '#6ff7f5',
        '92': '#75fdfb',
        '94': '#98fffd',
        '95': '#aefffd',
        '96': '#c1fffd',
        '98': '#e3fffe',
        '99': '#f1fffe',
        '100': '#ffffff'
    },
    neutral: {
        '0': '#000000',
        '4': '#0d003f',
        '6': '#150F35',
        '10': '#1a0261',
        '12': '#1e0965',
        '17': '#29196f',
        '20': '#302175',
        '22': '#34267a',
        '24': '#392b7f',
        '25': '#3b2d81',
        '30': '#463a8d',
        '35': '#52469a',
        '40': '#5e52a7',
        '50': '#786bc2',
        '60': '#9285de',
        '70': '#aca0fa',
        '80': '#c8bfff',
        '87': '#dcd5ff',
        '90': '#e5deff',
        '92': '#ebe5ff',
        '94': '#f1ebff',
        '95': '#f4eeff',
        '96': '#f7f1ff',
        '98': '#fdf8ff',
        '99': '#fffbff',
        '100': '#ffffff'
    },
    divergent: {
        '0': '#000000',
        '4': '#0d003f',
        '6': '#12004d',
        '10': '#1a0261',
        '12': '#1e0965',
        '17': '#29196f',
        '20': '#302175',
        '22': '#34267a',
        '24': '#392b7f',
        '25': '#3b2d81',
        '30': '#463a8d',
        '35': '#52469a',
        '40': '#5e52a7',
        '50': '#786bc2',
        '60': '#9285de',
        '70': '#aca0fa',
        '80': '#c8bfff',
        '87': '#dcd5ff',
        '90': '#e5deff',
        '92': '#ebe5ff',
        '94': '#f1ebff',
        '95': '#f4eeff',
        '96': '#f7f1ff',
        '98': '#fdf8ff',
        '99': '#fffbff',
        '100': '#ffffff'
    },
    disabled: {
        '0': '#000000',
        '4': '#0b0f0f',
        '6': '#101415',
        '10': '#191c1d',
        '12': '#1d2021',
        '17': '#272b2b',
        '20': '#2e3132',
        '22': '#323536',
        '24': '#363a3a',
        '25': '#393c3d',
        '30': '#444748',
        '35': '#505354',
        '40': '#5c5f5f',
        '50': '#747878',
        '60': '#8e9192',
        '70': '#a9acac',
        '80': '#c4c7c7',
        '87': '#d8dadb',
        '90': '#e1e3e3',
        '92': '#e6e8e9',
        '94': '#eceeef',
        '95': '#eff1f1',
        '96': '#f2f4f4',
        '98': '#f8fafa',
        '99': '#fafdfd',
        '100': '#ffffff'
    },
    error: {
        '0': '#000000',
        '4': '#270000',
        '6': '#310000',
        '10': '#410000',
        '12': '#490000',
        '17': '#5c0000',
        '20': '#690100',
        '22': '#710100',
        '24': '#790100',
        '25': '#7e0100',
        '30': '#930100',
        '35': '#a90100',
        '40': '#c00100',
        '50': '#ef0000',
        '60': '#ff5540',
        '70': '#ff8a78',
        '80': '#ffb4a8',
        '87': '#ffcfc7',
        '90': '#ffdad4',
        '92': '#ffe2dd',
        '94': '#ffe9e6',
        '95': '#ffedea',
        '96': '#fff0ee',
        '98': '#fff8f6',
        '99': '#fffbff',
        '100': '#ffffff'
    },
    warning: {
        '0': '#000000',
        '4': '#1c0900',
        '6': '#240d00',
        '10': '#311300',
        '12': '#371700',
        '17': '#461f00',
        '20': '#502400',
        '22': '#572800',
        '24': '#5d2b00',
        '25': '#612d00',
        '30': '#723600',
        '35': '#844000',
        '40': '#964900',
        '50': '#bb5d00',
        '60': '#e1730a',
        '70': '#ff8e34',
        '80': '#ffb786',
        '87': '#ffd1b4',
        '90': '#ffdcc6',
        '92': '#ffe3d2',
        '94': '#ffeade',
        '95': '#ffede4',
        '96': '#fff1ea',
        '98': '#fff8f5',
        '99': '#fffbff',
        '100': '#ffffff'
    },
    success: {
        '0': '#000000',
        '4': '#071100',
        '6': '#0b1700',
        '10': '#112000',
        '12': '#142400',
        '17': '#1b2f00',
        '20': '#203600',
        '22': '#233b00',
        '24': '#264000',
        '25': '#284300',
        '30': '#304f00',
        '35': '#395c00',
        '40': '#426900',
        '50': '#548400',
        '60': '#6aa018',
        '70': '#83bb35',
        '80': '#9dd84f',
        '87': '#b0ec60',
        '90': '#b8f568',
        '92': '#befb6d',
        '94': '#c8ff7f',
        '95': '#d3ff98',
        '96': '#ddffae',
        '98': '#f0ffd6',
        '99': '#f9ffe8',
        '100': '#ffffff'
    },
    info: {
        '0': '#000000',
        '4': '#000e24',
        '6': '#00132e',
        '10': '#001b3c',
        '12': '#001f43',
        '17': '#002a56',
        '20': '#003062',
        '22': '#003469',
        '24': '#003971',
        '25': '#003b75',
        '30': '#00468a',
        '35': '#00529f',
        '40': '#005eb4',
        '50': '#0076e0',
        '60': '#3691ff',
        '70': '#77acff',
        '80': '#a8c8ff',
        '87': '#c8dbff',
        '90': '#d5e3ff',
        '92': '#dee8ff',
        '94': '#e7eeff',
        '95': '#ecf1ff',
        '96': '#f0f3ff',
        '98': '#f9f9ff',
        '99': '#fdfbff',
        '100': '#ffffff'
    }
};

export {baseColors, tonal};
