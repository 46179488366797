import type {
    TAccent,
    TAccentKey,
    TColorTokenKey,
    TThemeType,
    TTonals
} from '../../defines/theme.types.js';

type TMap = {
    [k in TThemeType]: TAccent<TColorTokenKey>;
};

const map: TMap = {
    light: {
        high: {
            main: '40',
            contrast: '100',
            overlay: '0',
            state: {
                hover: '30',
                focus: '30',
                active: '30'
            }
        },
        low: {
            main: '90',
            contrast: '10',
            overlay: '20',
            state: {
                hover: '80',
                focus: '80',
                active: '80'
            }
        }
    },
    dark: {
        high: {
            main: '80',
            contrast: '20',
            overlay: '100',
            state: {
                hover: '90',
                focus: '90',
                active: '90'
            }
        },
        low: {
            main: '30',
            contrast: '90',
            overlay: '80',
            state: {
                hover: '40',
                focus: '40',
                active: '40'
            }
        }
    }
};

function createAccent(
    tonals: TTonals,
    accent: TAccentKey,
    type: TThemeType
): TAccent {
    const tokens = tonals[accent];

    const {high, low} = map[type];

    return {
        high: {
            main: tokens[high.main],
            contrast: tokens[high.contrast],
            overlay: tokens[high.overlay],
            state: {
                hover: tokens[high.state.hover],
                focus: tokens[high.state.focus],
                active: tokens[high.state.active]
            }
        },
        low: {
            main: tokens[low.main],
            contrast: tokens[low.contrast],
            overlay: tokens[low.overlay],
            state: {
                hover: tokens[low.state.hover],
                focus: tokens[low.state.focus],
                active: tokens[low.state.active]
            }
        }
    };
}

export {createAccent};
