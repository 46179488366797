import {useTheme} from '@emotion/react';
import {default as styled} from '@emotion/styled';
import {alpha} from '@mui/system';
import type {FC} from 'react';
import {useCallback} from 'react';
import {DarkModeSwitch} from 'react-toggle-dark-mode';
import {useStyling} from '../../styling/hooks/use-styling/use-styling.js';

const Container = styled('span')(({theme}) => ({
    position: 'fixed',
    bottom: theme.sizing.layout.s4,
    right: theme.sizing.layout.s4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: theme.sizing.font.s8,
    width: theme.sizing.font.s8,
    borderRadius: theme.shape.borderRadius.full,
    zIndex: theme.zIndex.global.top,
    '& > svg': {
        zIndex: theme.zIndex.local.top
    },
    '&::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: '100%',
        width: '100%',
        borderRadius: theme.shape.borderRadius.full,
        zIndex: theme.zIndex.local.high,
        transition: 'background linear 150ms'
    },
    '&:hover::after': {
        backgroundColor: alpha(theme.palette.accent.neutral.low.main, 0.2)
    }
}));

const ToggleThemeType: FC = () => {
    const {currentThemeType, changeThemeType} = useStyling();

    const {palette, sizing} = useTheme();

    const onChange = useCallback(
        (value: boolean) => {
            if (value) {
                changeThemeType('dark');
            } else {
                changeThemeType('light');
            }
        },
        [changeThemeType]
    );

    return (
        <Container>
            <DarkModeSwitch
                checked={currentThemeType === 'dark'}
                onChange={onChange}
                size={sizing.font.s5}
                sunColor={palette.accent.warning.high.main}
                moonColor={palette.accent.neutral.high.main}
            />
        </Container>
    );
};

export {ToggleThemeType};
