import {default as styled} from '@emotion/styled';
import type {FC} from 'react';
import {LettergyMarkSvg} from '../../assets/svg/lettergy-mark-svg.js';
import {Text} from '../../components/text/text.js';

interface IContainerProps {
    corridor: string;
}

const Container = styled('div')<IContainerProps>(({theme, corridor}) => ({
    position: 'relative',
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.sizing.layout.s8,
    paddingLeft: corridor,
    marginBottom: theme.sizing.layout.s10
}));

interface ILogoAreaProps {
    corridor: string;
}

const LogoArea = styled('div')<ILogoAreaProps>(({theme, corridor}) => ({
    width: corridor,
    position: 'absolute',
    top: theme.sizing.layout.s6,
    left: 0
}));

const LogoContainer = styled('div')({
    width: '100%',
    padding: `0px 15%`
});

const Heading = styled(Text)(({theme}) => ({
    fontSize: theme.sizing.font.s7,
    margin: `0px ${theme.sizing.layout.s4} 0px ${theme.sizing.layout.s5}`,
    color: theme.palette.background.contrast.high,

    [theme.query.m]: {
        fontSize: theme.sizing.font.s10
    }
}));

const Scroll = styled(Text)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.sizing.font.s7,
    margin: `${theme.sizing.layout.s4} 0px 0px ${theme.sizing.layout.s5}`,
    color: theme.palette.background.contrast.disabled,

    [theme.query.m]: {
        fontSize: theme.sizing.font.s10
    }
}));

const Triangle = styled('span')(({theme}) => ({
    display: 'inline-block',
    color: theme.palette.background.contrast.low,
    width: 0,
    height: 0,
    borderLeft: '20px solid transparent',
    borderRight: '20px solid transparent',
    borderTop: `20px solid ${theme.palette.background.contrast.disabled}`,
    marginLeft: theme.sizing.layout.s3,

    [theme.query.m]: {
        fontSize: theme.sizing.font.s10
    }
}));

const P = styled('p')({
    margin: 0
});

const Span = styled('span')({});

const GradientText = styled('span')(
    ({theme}) => {
        const colorA = theme.palette.accent.primary.high.main;

        return {
            background: colorA,
            WebkitBackgroundClip: 'text !important',
            color: 'rgba(0,0,0,0) !important'
        };
    },
    ({theme}) => {
        const colorA = theme.palette.accent.primary.high.main;
        const colorB = theme.palette.accent.tertiary.high.main;

        return {
            backgroundClip: 'text !important',
            background: `linear-gradient(90deg, ${colorA} 30%, ${colorB} 100%)`
        };
    }
);

interface IHeroProps {
    corridor: string;
}

const Hero: FC<IHeroProps> = ({corridor}) => {
    return (
        <Container corridor={corridor}>
            <LogoArea corridor={corridor}>
                <LogoContainer>
                    <LettergyMarkSvg width={'100%'} height={corridor} />
                </LogoContainer>
            </LogoArea>

            <Heading variant={'h1'} weight={800}>
                <P>{'Ever wondered'}</P>

                <P>
                    <Span>{' what'}</Span>
                    <GradientText>
                        {' the next generation of emails '}
                    </GradientText>
                </P>

                <P>{'will look like?'}</P>
            </Heading>

            <Scroll variant={'h1'} weight={800}>
                {'Scroll'}

                <Triangle />
            </Scroll>
        </Container>
    );
};

export type {IHeroProps};
export {Hero};
