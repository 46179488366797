import {useContext} from 'react';
import {ThemeContext} from '../../contexts/theme-store/theme-store.js';

function useStyling() {
    const {packs, currentPack, changePack, currentType, changeType} =
        useContext(ThemeContext);

    return {
        themePacks: packs,
        currentThemePack: currentPack,
        changeThemePack: changePack,
        currentThemeType: currentType,
        changeThemeType: changeType
    };
}

export {useStyling};
