import type {FC} from 'react';
import type {IIconProps} from '../../defines/common.types.js';

const CouponIcon: FC<IIconProps> = (props) => {
    return (
        <svg
            xmlns={'http://www.w3.org/2000/svg'}
            xmlnsXlink={'http://www.w3.org/1999/xlink'}
            viewBox={'0 0 50 50'}
            width={'24px'}
            height={'24px'}
            {...props}
        >
            <circle cx={'14'} cy={'19'} r={'1.1'} />
            <path
                d={
                    'M7.6,25.4c-0.6-0.6-1.5-0.6-2.1,0c-0.2,0.2-0.8,0.8-1,1c-0.6,0.6-0.6,1.5,0,2.1s1.5,0.6,2.1,0c0.2-0.2,0.8-0.8,1-1C8.1,27,8.1,26,7.6,25.4z'
                }
            />
            <path
                d={
                    'M7.6,12.6c0.6-0.6,0.6-1.5,0-2.1c-0.2-0.2-0.8-0.8-1-1C6,8.9,5,8.9,4.4,9.4s-0.6,1.5,0,2.1c0.2,0.2,0.8,0.8,1,1C6,13.1,7,13.1,7.6,12.6z'
                }
            />
            <path
                fill={'none'}
                stroke={'currentcolor'}
                strokeWidth={'2'}
                strokeLinejoin={'round'}
                strokeMiterlimit={'10'}
                d={
                    'M30.4,6h-4.3c-1.6,0-3.1,0.6-4.2,1.8L7.5,22.1c-1.2-0.2-2.5,0.2-3.4,1.1c-0.4,0.4-1.6,1.6-2,2c-1.6,1.6-1.6,4.1,0,5.7c1.6,1.6,4.1,1.6,5.7,0c0.4-0.4,1.6-1.6,2-2c0.9-0.9,1.3-2.2,1.1-3.4L30.4,6z'
                }
            />
            <path
                fill={'none'}
                stroke={'currentcolor'}
                strokeWidth={'2'}
                strokeLinejoin={'round'}
                strokeMiterlimit={'10'}
                d={'M19.5,21.1L30.4,32h-4.3c-1.6,0-3.1-0.6-4.2-1.8l-5.7-5.7'}
            />
            <path
                fill={'none'}
                stroke={'currentcolor'}
                strokeWidth={'2'}
                strokeLinejoin={'round'}
                strokeMiterlimit={'10'}
                d={
                    'M13.9,15.5l-3-3c0.2-1.2-0.2-2.5-1.1-3.4c-0.4-0.4-1.6-1.6-2-2c-1.6-1.6-4.1-1.6-5.7,0c-1.6,1.6-1.6,4.1,0,5.7c0.4,0.4,1.6,1.6,2,2c0.9,0.9,2.2,1.3,3.4,1.1l3,3'
                }
            />
            <rect x={'10'} y={'40'} width={'2'} height={'4'} />
            <rect x={'10'} y={'42'} width={'4'} height={'2'} />
            <rect x={'46'} y={'42'} width={'4'} height={'2'} />
            <rect x={'40'} y={'42'} width={'4'} height={'2'} />
            <rect x={'34'} y={'42'} width={'4'} height={'2'} />
            <rect x={'28'} y={'42'} width={'4'} height={'2'} />
            <rect x={'22'} y={'42'} width={'4'} height={'2'} />
            <rect x={'16'} y={'42'} width={'4'} height={'2'} />
            <rect x={'40'} y={'16'} width={'4'} height={'2'} />
            <rect x={'34'} y={'16'} width={'4'} height={'2'} />
            <rect x={'28'} y={'16'} width={'4'} height={'2'} />
            <polygon points={'22.6,18 24.6,16 26,16 26,18 '} />
            <rect x={'48'} y={'40'} width={'2'} height={'4'} />
            <rect x={'48'} y={'16'} width={'2'} height={'4'} />
            <rect x={'48'} y={'22'} width={'2'} height={'4'} />
            <rect x={'48'} y={'28'} width={'2'} height={'4'} />
            <rect x={'48'} y={'34'} width={'2'} height={'4'} />
            <polygon points={'12,38 10,38 10,34 12,32 '} />
            <rect x={'46'} y={'16'} width={'4'} height={'2'} />
        </svg>
    );
};

export {CouponIcon};
