import {default as styled} from '@emotion/styled';
import type {FC} from 'react';
import {Fragment, useMemo} from 'react';
import {StackableCard} from '../../components/stackable-card/stackable-card.js';
import {Text} from '../../components/text/text.js';
import {useBreakpoints} from '../../hooks/use-breakpoints/use-breakpoints.js';
import type {TSizingTokenKey} from '../../styling/defines/theme.types.js';

const Container = styled('div')({
    width: '100%',
    paddingTop: 50,
    paddingBottom: 50
});

const Spacer = styled('div')({
    width: '100%',
    height: 700
});

const Content = styled('div')(({theme}) => ({
    height: '100%',
    width: '100%',
    padding: `0px ${theme.sizing.layout.s3}`
}));

interface IStackProps {
    corridor: string;
}

const Stack: FC<IStackProps> = ({corridor}) => {
    const size = useBreakpoints<TSizingTokenKey>({
        base: 's4',
        s: 's4'
    });

    const steps = useMemo(() => {
        return [
            {
                id: 'step-1',
                label: 'ANCIENT CHINA',
                content: (
                    <Content>
                        <Text size={size} margin={'s0'}>
                            {
                                'China inaugurated a methodical postal service, enabling the swift transfer of information through messengers on foot and horseback.'
                            }
                        </Text>
                    </Content>
                ),
                time: '900 B.C'
            },
            {
                id: 'step-2',
                label: 'ANCIENT ROME',
                content: (
                    <Content>
                        <Text size={size} margin={'s0'}>
                            {
                                'Back in ancient Rome, people relied on pigeons to carry their messages.'
                            }
                        </Text>
                    </Content>
                ),
                time: '500 B.C'
            },
            {
                id: 'step-3',
                label: 'ROYAL MAIL',
                content: (
                    <Content>
                        <Text size={size} margin={'s0'}>
                            {
                                "In King Henry VIII's England, the foundations of the Royal Mail emerged, establishing regular postal routes and revolutionizing message delivery."
                            }
                        </Text>
                    </Content>
                ),
                time: '1516'
            },
            {
                id: 'step-4',
                label: 'MODERN EMAILS',
                content: (
                    <Content>
                        <Text size={size} margin={'s0'}>
                            {
                                'Ray Tomlinson sends the first networked email, setting the stage for a platform that now facilitates over 100 billion message exchanges daily.'
                            }
                        </Text>
                    </Content>
                ),
                time: '1971'
            },
            {
                id: 'step-5',
                label: 'A STANDSTILL',
                content: (
                    <Content>
                        <Text size={size} margin={'s0'}>
                            {
                                'The fundamental principles of messaging remain largely unaltered.'
                            }
                        </Text>
                    </Content>
                ),
                time: 'TODAY'
            }
        ];
    }, [size]);

    return (
        <Container>
            {steps.map(({id, label, content, time}, index) => {
                return (
                    <Fragment key={id}>
                        <StackableCard
                            label={label}
                            content={content}
                            time={time}
                            width={`calc(95% - ${corridor})`}
                            maxWidth={800}
                            height={350}
                            top={30}
                            gap={50}
                            index={index}
                            corridor={corridor}
                        />

                        <Spacer />
                    </Fragment>
                );
            })}
        </Container>
    );
};

export type {IStackProps};
export {Stack};
