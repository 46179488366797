import type {FC} from 'react';
import type {IIconProps} from '../../defines/common.types.js';

const MoreIcon: FC<IIconProps> = (props) => {
    return (
        <svg
            xmlns={'http://www.w3.org/2000/svg'}
            xmlnsXlink={'http://www.w3.org/1999/xlink'}
            viewBox={'0 0 50 50'}
            width={'24px'}
            height={'24px'}
            {...props}
        >
            <circle
                fill={'none'}
                stroke={'currentcolor'}
                strokeWidth={'2'}
                strokeMiterlimit={'10'}
                cx={'25'}
                cy={'25'}
                r={'5'}
            />
            <circle
                fill={'none'}
                stroke={'currentcolor'}
                strokeWidth={'2'}
                strokeMiterlimit={'10'}
                cx={'41'}
                cy={'25'}
                r={'5'}
            />
            <circle
                fill={'none'}
                stroke={'currentcolor'}
                strokeWidth={'2'}
                strokeMiterlimit={'10'}
                cx={'9'}
                cy={'25'}
                r={'5'}
            />
        </svg>
    );
};

export {MoreIcon};
