import {default as styled} from '@emotion/styled';
import type {FC, ReactNode} from 'react';
import {Text} from '../text/text.js';

const List = styled('ul')(({theme}) => ({
    padding: theme.sizing.layout.s3,
    listStyleType: 'none'
}));

const Item = styled('li')(({theme}) => ({
    marginTop: theme.sizing.font.s5,
    marginBottom: theme.sizing.font.s5,
    marginLeft: '10%',
    position: 'relative',
    '&::after': {
        content: '""',
        position: 'absolute',
        top: 15,
        left: '-40px',
        height: '12px',
        width: '12px',
        borderRadius: theme.shape.borderRadius.full,
        zIndex: theme.zIndex.local.high,
        backgroundColor: theme.palette.surface.contrast.high
    }
}));

interface ITextListProps {
    items: ReactNode[][];
}

const TextList: FC<ITextListProps> = ({items}) => {
    return (
        <List>
            {items.map(([title, content], index) => {
                return (
                    <Item key={index}>
                        <Text variant={'span'} size={'s5'} weight={800}>
                            {title}
                        </Text>
                        <Text variant={'span'} size={'s5'}>
                            {content}
                        </Text>
                    </Item>
                );
            })}
        </List>
    );
};

export type {ITextListProps};
export {TextList};
