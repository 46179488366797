import {useTheme} from '@emotion/react';
import type {FC} from 'react';
import {useMemo} from 'react';
import type {ISvgProps} from '../../defines/common.types.js';

interface IColors {
    colorA: string;
    colorB: string;
    colorC: string;
}

const LettergyMarkSvg: FC<ISvgProps> = (props) => {
    const {type} = useTheme();

    const {colorA, colorB, colorC} = useMemo<IColors>(() => {
        if (type === 'dark') {
            return {
                colorA: '#FFFFFF',
                colorB: '#9DFFFD',
                colorC: '#B892F2'
            };
        } else {
            return {
                colorA: '#150F35',
                colorB: '#9DFFFD',
                colorC: '#B892F2'
            };
        }
    }, [type]);

    return (
        <svg
            xmlns={'http://www.w3.org/2000/svg'}
            viewBox={'0 0 142 116'}
            width={'142'}
            height={'116'}
            fill={'none'}
            {...props}
        >
            <g clipPath={'url(#clip0_300_710)'}>
                <path
                    d={
                        'M134.35 28.9698C133.43 28.3598 132.18 27.7998 131.14 27.4398C128.16 26.5798 125.13 25.8598 122.08 25.2898V45.5798C117.87 48.8698 114.33 51.5498 111.05 53.9198H111.03L111.01 53.9398C97.5399 63.7698 83.2899 71.8098 71.1199 78.3798L71.0099 78.4398L70.8999 78.4998C70.8999 78.4998 70.7899 78.5398 70.6799 78.5398C70.6099 78.5398 70.5599 78.5298 70.5499 78.5198H70.5299L70.5099 78.4998C69.7899 78.1198 68.3399 77.3298 67.1699 76.6898C66.5599 76.3598 65.9899 76.0398 65.5799 75.8198C59.2799 72.3598 53.1799 68.8198 47.4499 65.3098C38.5899 59.9098 29.3799 53.4498 19.3399 45.5598L19.3099 25.2798C16.6099 25.7898 13.9199 26.3998 11.2599 27.0798C10.9799 27.1398 10.5999 27.2798 10.2299 27.4098L9.12994 27.8098C0.589943 31.5198 -2.53006 42.4498 2.22994 50.4198C2.90994 51.6098 3.72994 52.6198 4.60994 53.6198C7.24994 56.6498 10.0099 59.5698 12.8699 62.3898V103.99C12.8699 110.44 18.0999 115.67 24.5499 115.67H116.85C123.3 115.67 128.53 110.44 128.53 103.99V62.3598C131.38 59.5398 134.13 56.6298 136.77 53.6098C137.3 52.9498 137.97 52.2598 138.43 51.5498C138.82 50.9098 139.51 49.9098 139.78 49.2298C143.25 42.2298 140.92 33.1298 134.34 28.9798L134.35 28.9698Z'
                    }
                    fill={colorA}
                />

                <g filter={'url(#filter0_i_300_710)'}>
                    <path
                        d={
                            'M19.33 19.3V11.68C19.33 5.23 24.5601 0 31.01 0H110.4C116.85 0 122.08 5.23 122.08 11.68V45.57C117.87 48.86 114.33 51.54 111.05 53.91H111.03L111.01 53.93C97.5401 63.76 83.2901 71.8 71.1201 78.37L71.0101 78.43L70.9001 78.49C70.9001 78.49 70.79 78.53 70.68 78.53C70.61 78.53 70.5601 78.52 70.5501 78.51H70.53L70.5101 78.49C69.7901 78.11 68.34 77.32 67.17 76.68C66.56 76.35 65.9901 76.03 65.5801 75.81C59.2801 72.35 53.1801 68.81 47.4501 65.3C38.5901 59.9 29.38 53.44 19.34 45.55L19.3 19.27L19.33 19.3Z'
                        }
                        fill={'url(#paint0_linear_300_710)'}
                    />
                </g>

                <path
                    d={
                        'M92.88 39.0401C92.88 39.6801 92.49 40.2701 91.89 40.5001L82.5801 44.0701C79.3001 45.3301 76.74 47.9501 75.53 51.2601L71.99 61.0101C71.96 61.0801 71.9101 61.1301 71.8701 61.2001C71.8401 61.2501 71.81 61.3101 71.77 61.3601C71.71 61.4401 71.66 61.5001 71.59 61.5601C71.48 61.6601 71.3601 61.7401 71.2301 61.8001C71.1501 61.8301 71.0601 61.8601 70.9801 61.8701C70.9001 61.8901 70.8101 61.9001 70.7301 61.9001L70.46 61.8701C70.38 61.8601 70.29 61.8301 70.21 61.8001C70.07 61.7401 69.95 61.6501 69.84 61.5501C69.77 61.4901 69.7201 61.4201 69.6601 61.3501C69.6301 61.3101 69.6001 61.2601 69.5801 61.2201C69.5401 61.1501 69.4701 61.0901 69.4401 61.0101L65.9001 51.2601C64.7101 47.9501 62.1401 45.3301 58.8601 44.0701L49.55 40.5001C48.95 40.2701 48.55 39.6901 48.55 39.0401C48.55 38.3601 48.99 37.7601 49.64 37.5601L58.16 34.8801C61.85 33.7201 64.75 30.8601 66 27.2101L69.4701 16.9701C69.5101 16.8501 69.57 16.7501 69.63 16.6501C69.67 16.5901 69.71 16.5201 69.78 16.4701C69.91 16.3301 70.0701 16.2301 70.2601 16.1501C70.3101 16.1301 70.3801 16.1101 70.4401 16.1001C70.5401 16.0701 70.64 16.0601 70.75 16.0601C70.86 16.0601 70.96 16.0701 71.06 16.1001C71.12 16.1101 71.19 16.1301 71.24 16.1501C71.41 16.2101 71.56 16.3201 71.68 16.4401C71.73 16.4901 71.78 16.5501 71.82 16.6001C71.9 16.7201 71.98 16.8301 72.02 16.9701L75.49 27.2101C76.73 30.8701 79.64 33.7201 83.32 34.8801L91.84 37.5601C92.5 37.7601 92.93 38.3601 92.93 39.0401H92.88Z'
                    }
                    fill={colorA}
                />
            </g>

            <defs>
                <filter
                    id={'filter0_i_300_710'}
                    x={'19.3'}
                    y={'0'}
                    width={'102.78'}
                    height={'82.5298'}
                    filterUnits={'userSpaceOnUse'}
                    colorInterpolationFilters={'sRGB'}
                >
                    <feFlood floodOpacity={'0'} result={'BackgroundImageFix'} />

                    <feBlend
                        mode={'normal'}
                        in={'SourceGraphic'}
                        in2={'BackgroundImageFix'}
                        result={'shape'}
                    />

                    <feColorMatrix
                        in={'SourceAlpha'}
                        type={'matrix'}
                        values={'0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'}
                        result={'hardAlpha'}
                    />

                    <feOffset dy={'4'} />

                    <feGaussianBlur stdDeviation={'5'} />

                    <feComposite
                        in2={'hardAlpha'}
                        operator={'arithmetic'}
                        k2={'-1'}
                        k3={'1'}
                    />

                    <feColorMatrix
                        type={'matrix'}
                        values={
                            '0 0 0 0 0.870588 0 0 0 0 0.792157 0 0 0 0 0.992157 0 0 0 0.5 0'
                        }
                    />

                    <feBlend
                        mode={'normal'}
                        in2={'shape'}
                        result={'effect1_innerShadow_300_710'}
                    />
                </filter>

                <linearGradient
                    id={'paint0_linear_300_710'}
                    x1={'70.71'}
                    y1={'78.55'}
                    x2={'70.71'}
                    y2={'0'}
                    gradientUnits={'userSpaceOnUse'}
                >
                    <stop stopColor={colorB} />
                    <stop offset={'1'} stopColor={colorC} />
                </linearGradient>

                <clipPath id={'clip0_300_710'}>
                    <rect width={'141.39'} height={'115.67'} fill={'white'} />
                </clipPath>
            </defs>
        </svg>
    );
};

export {LettergyMarkSvg};
