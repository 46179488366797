import {useTheme} from '@emotion/react';
import {default as styled} from '@emotion/styled';
import {animated, useSpring} from '@react-spring/web';
import type {FC, MouseEvent, ReactNode} from 'react';
import {useCallback, useState} from 'react';

const Container = styled(animated.div)(({theme}) => ({
    position: 'relative',
    flex: 1,
    minWidth: '50vw',
    minHeight: 150,
    maxWidth: 600,
    zIndex: theme.zIndex.global.mid,
    cursor: 'pointer',
    [theme.query.m]: {
        minWidth: '30vw'
    }
}));

const Side = styled(animated.div)(({theme}) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    boxShadow: theme.elevation.mid,
    backgroundColor: theme.palette.surface.high,
    borderRadius: theme.shape.borderRadius.large
}));

const MeasureBox = styled(animated.div)({
    position: 'relative',
    visibility: 'hidden',
    display: 'flex',
    alignItems: 'flex-start',
    overflowX: 'hidden',
    zIndex: -1000,
    '& > *': {
        flexGrow: 0,
        flexShrink: 0
    }
});

interface IInteractiveCardProps {
    sideA: ReactNode;
    sideB: ReactNode;
}

const InteractiveCard: FC<IInteractiveCardProps> = ({sideA, sideB}) => {
    const {zIndex} = useTheme();

    const [flipped, setFlipped] = useState<boolean>(false);

    const [perspective, api] = useSpring(() => ({
        xys: [0, 0, 1],
        config: {mass: 5, tension: 350, friction: 40}
    }));

    const {transform, opacity} = useSpring({
        opacity: flipped ? 1 : 0,
        transform: `perspective(1000px) rotateY(${flipped ? 180 : 0}deg)`,
        config: {mass: 3, tension: 500, friction: 80}
    });

    const interpolator = useCallback((x: number, y: number, s: number) => {
        return `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;
    }, []);

    const onMouseMoveHandler = useCallback(
        ({currentTarget, clientX, clientY}: MouseEvent<HTMLElement>) => {
            const rect = currentTarget.getBoundingClientRect();

            const offsetX = clientX - rect.left;
            const offsetY = clientY - rect.top;

            const xys = [
                -(offsetY - rect.height / 2) / 50,
                (offsetX - rect.width / 2) / 100,
                1.05
            ];

            api.start({xys});
        },
        [api]
    );

    const onMouseLeaveHandler = useCallback(() => {
        api.start({xys: [0, 0, 1]});
    }, [api]);

    const onClickHandler = useCallback(() => {
        setFlipped((prevFlipped) => !prevFlipped);
    }, []);

    return (
        <Container
            onMouseMove={onMouseMoveHandler}
            onMouseLeave={onMouseLeaveHandler}
            onClick={onClickHandler}
            style={{
                transform: perspective.xys.to(interpolator)
            }}
        >
            <MeasureBox>
                {sideA}
                {sideB}
            </MeasureBox>

            <Side
                style={{
                    opacity: opacity.to((o) => 1 - o),
                    transform,
                    zIndex: flipped ? zIndex.global.mid : zIndex.global.high
                }}
            >
                {sideA}
            </Side>

            <Side
                style={{
                    opacity,
                    transform: transform.to((t) => `${t} rotateY(180deg)`),
                    zIndex: flipped ? zIndex.global.high : zIndex.global.mid
                }}
            >
                {sideB}
            </Side>
        </Container>
    );
};

export type {IInteractiveCardProps};
export {InteractiveCard};
