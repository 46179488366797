import {useContext, useMemo} from 'react';
import {MediaQueriesContext} from '../../contexts/media-queries-store/media-queries-store.js';
import type {TBreakpointKey} from '../../styling/defines/theme.types.js';

const keys: TBreakpointKey[] = ['xs', 's', 'm', 'l', 'xl', 'xxl'];

type TConfig<T> = {[k in TBreakpointKey]?: T} & {base: T};

function useBreakpoints<T>(config: TConfig<T>): T {
    const {breakpoint} = useContext(MediaQueriesContext);

    return useMemo<T>(() => {
        if (!breakpoint) {
            return config.base;
        }

        const currentIndex = keys.indexOf(breakpoint);

        for (let i = currentIndex; i >= 0; i--) {
            const key = keys[i];

            if (key in config) {
                return config[key] as T;
            }
        }

        return config.base;
    }, [breakpoint, config]);
}

export {useBreakpoints};
